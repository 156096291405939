import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getNewSubtotalUtility, getTotalChargesReturns } from '../../../../../config/utils/reportUtils';
import { CURRENCY } from '../../../../../interfaces/enumerables';
import { CustomerReportResponse } from '../../../../../interfaces/reports.interface';
import { DocsReportTableHeaderView } from './DocsReportTableHeaderView';
import { DocsTableListView } from './DocsTableListView';

interface Props {
  data: CustomerReportResponse;
}

export const CustomerReportView = ({ data }: Props) => {
  const { t } = useTranslation();

  const finalSubtotals = useMemo(() => {
    const len = data?.data?.length;
    if (len > -1) {
      return getNewSubtotalUtility(data?.data?.[len - 1]);
    }

    return { rawValue: '0.00', currentPosSubtotal: '', currentNegSubtotal: '' };
  }, [data?.data]);

  const getPosSubtotal = useCallback(() => {
    if (finalSubtotals?.currentPosSubtotal) {
      return finalSubtotals?.currentPosSubtotal;
    }
    return '0.00';
  }, [finalSubtotals?.currentPosSubtotal]);

  const getNegSubtotal = useCallback(() => {
    if (finalSubtotals?.currentNegSubtotal) {
      return finalSubtotals?.currentNegSubtotal;
    }
    return '0.00';
  }, [finalSubtotals?.currentNegSubtotal]);

  const getTotals = useCallback(() => {
    return getTotalChargesReturns(data?.data || []);
  }, [data?.data]);

  return (
    <div className="flex-center-center-column-nowrap padding-t-10 width-100">
      <div className="width-100">
        <DocsReportTableHeaderView />
      </div>
      <div className="width-100">
        <DocsTableListView docs={data.data} />
      </div>
      <div className="width-100 padding-t-10 padding-r-4 padding-l-20 flex-start-center-row-nowrap">
        <div className="boldText3 width-418px">{t('CUSTOMERS_REPORT.TOTAL')}</div>
        <div className="boldText3 width-80px text-center">
          {getTotals()?.charges} {CURRENCY.EURO}
        </div>
        <div className="boldText3 width-80px text-center">
          {getTotals()?.returns} {CURRENCY.EURO}
        </div>
        <div className="boldText3 width-80px text-center">
          {getPosSubtotal()} {CURRENCY.EURO}
        </div>
        <div className="boldText3 width-80px text-center">
          {getNegSubtotal()} {CURRENCY.EURO}
        </div>
      </div>
    </div>
  );
};
