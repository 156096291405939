import { Document, Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Company } from '../../../../../interfaces/company.interface';

// @ts-expect-error Import
import ManropeRegular from '../../../../../theme/assets/fonts/Manrope-Regular.ttf';
// @ts-expect-error Import
import ManropeMedium from '../../../../../theme/assets/fonts/Manrope-Medium.ttf';
// @ts-expect-error Import
import ManropeBold from '../../../../../theme/assets/fonts/Manrope-Bold.ttf';

import { useTranslation } from 'react-i18next';
import { pdfStyles } from '../../../../../components/molecules/pdf/pdfStyles';
import { getFullDateTime } from '../../../../../config/utils/dateHelpers';
import { Customer } from '../../../../../interfaces/customer.interface';
import { CustomersReportTableSectionPdf } from './CustomersReportTableSectionPdf';

interface Props {
  company: Company;
  customers: Customer[];
}

// Register Font
Font.register({
  family: 'Manrope',
  fonts: [
    {
      src: ManropeRegular,
    },
    {
      src: ManropeMedium,
    },
    {
      src: ManropeBold,
    },
  ],
});

export const CustomersReportPdf = ({ company, customers }: Props) => {
  const { t } = useTranslation();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.header]}>
          <View style={{ borderBottom: 1, paddingLeft: 4, paddingRight: 4 }}>
            <Text style={pdfStyles.headerTitle}>{t('CUSTOMERS_REPORT.CURRENT_CUSTOMERS_SUBTOTALS')}</Text>
          </View>
          <View style={[styles.columnSection, styles.padding10]}>
            <Text>
              {company?.title} - {t('CUSTOMERS_REPORT.VAT_NUMBER')}: {company?.vatNumber}
            </Text>
            <View style={{ paddingTop: 6 }}>
              <Text style={pdfStyles.smallTitle}>{getFullDateTime(new Date().toISOString())}</Text>
            </View>
          </View>
          <View style={{ paddingTop: 4, width: '100%' }}>
            <CustomersReportTableSectionPdf customers={customers} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  header: {
    margin: 10,
    marginTop: 0,
    padding: 10,
    paddingTop: 0,
    marginBottom: 60,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  padding10: {
    padding: 10,
  },
  columnSection: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  separatorLine: {
    width: '100%',
    borderBottomWidth: 1,
  },
  page: {
    fontFamily: 'Manrope',
    fontSize: 12,
    flexDirection: 'column',
    backgroundColor: 'white',
    paddingTop: 30,
    paddingBottom: 30,
    paddingHorizontal: 4,
  },
});
