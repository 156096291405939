import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { pdfStyles } from '../../../../../components/molecules/pdf/pdfStyles';
import { getLocalDate } from '../../../../../config/utils/dateHelpers';
import { getChargesUtility, getNewSubtotalUtility } from '../../../../../config/utils/reportUtils';
import { CustomerReportDoc, CUSTOMER_REPORT_ENTITY_TYPE } from '../../../../../interfaces/reports.interface';

interface Props {
  index: number;
  item: CustomerReportDoc;
}

export const CustomerTableLinePdf = ({ index, item }: Props) => {
  const { t } = useTranslation();
  const docDate = useMemo(() => getLocalDate(item?.documentDate || ''), [item?.documentDate]);

  const getChargesSection = useCallback(() => {
    const results = getChargesUtility(item);

    return (
      <>
        <Text style={[pdfStyles.smallTitleRegular, pdfStyles.width60]}>{results?.currentPosSubtotal || '0.00'}</Text>
        <Text style={[pdfStyles.smallTitleRegular, pdfStyles.width60]}>{results?.currentNegSubtotal || '0.00'}</Text>
      </>
    );
  }, [item]);

  const getSubtotalsSection = useCallback(() => {
    const results = getNewSubtotalUtility(item);

    return (
      <>
        <Text style={[pdfStyles.smallTitleRegular, pdfStyles.width60]}>{results?.currentPosSubtotal || '0.00'}</Text>
        <Text style={[pdfStyles.smallTitleRegular, pdfStyles.width60]}>{results?.currentNegSubtotal || '0.00'}</Text>
      </>
    );
  }, [item]);

  const getDocName = useCallback(() => {
    // Money Collection case
    if (item?.entity_type === CUSTOMER_REPORT_ENTITY_TYPE.MONEY_COLLECTION) {
      return `${t('CUSTOMERS_REPORT.MONEY_COLLECTION_DOC')} - ${t(`MONEY_COLLECTION_PAYMENT_TYPES.${item?.paymentType}`)}`;
    }

    return t(`DOC_TYPES.${item?.documentTypeName}`);
  }, [item?.documentTypeName, item?.entity_type, item?.paymentType, t]);

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: 'column' }}>
        <View style={[styles.indexSection]}>
          <Text style={pdfStyles.smallTitleRegular}>{index + 1}.</Text>
        </View>
        <Text style={[pdfStyles.smallTitleRegular, pdfStyles.width60]}>{docDate}</Text>
      </View>
      <Text style={[pdfStyles.smallTitleRegular, pdfStyles.width80, pdfStyles.maxLines1]}>
        {item?.seriesName}({item?.serialNumber})
      </Text>
      <Text style={[pdfStyles.smallTitle, styles.width163, pdfStyles.maxLines1, { paddingLeft: 4 }]}>
        {getDocName()}
      </Text>
      {getChargesSection()}
      {getSubtotalsSection()}
    </View>
  );
};

const styles = StyleSheet.create({
  indexSection: {
    position: 'absolute',
    left: -14,
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingTop: 2,
  },
  width90: {
    width: 90,
    textAlign: 'center',
  },
  width60: {
    width: 60,
    textAlign: 'center',
  },
  width40: {
    width: 40,
    textAlign: 'center',
  },
  width163: {
    width: 163,
  },
});
