import { useQuery } from 'react-query';
import { MoneyCollection } from '../../../interfaces/moneyCollection.interface';
import { apiRQ } from '../../api';

interface PublicMoneyCollectionResponse {
  success: boolean;
  error?: string;
  errorCode?: string;
  data?: MoneyCollection;
}

const getPublicMoneyCollectionService = async (id: string): Promise<PublicMoneyCollectionResponse> => {
  const resp = await apiRQ.get<PublicMoneyCollectionResponse>(`/share/money-collection/${id}`);

  return resp.data;
};

export const usePublicMoneyCollection = (id: string) => {
  return useQuery<PublicMoneyCollectionResponse, Error>(
    ['usePublicMoneyCollection', id],
    () => getPublicMoneyCollectionService(id),
    {
      enabled: !!id,
      cacheTime: 10000,
      staleTime: 10000,
      refetchOnWindowFocus: false,
    },
  );
};
