import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocalDate } from '../../../../../config/utils/dateHelpers';
import { CustomerReportDoc, CUSTOMER_REPORT_ENTITY_TYPE } from '../../../../../interfaces/reports.interface';
import { LineChargesSection } from './LineChargesSection';
import { LineSubtotalsSection } from './LineSubtotalsSection';

interface Props {
  index: number;
  doc: CustomerReportDoc;
}

export const DocTableLineView = ({ index, doc }: Props) => {
  const { t } = useTranslation();
  const docDate = useMemo(() => getLocalDate(doc?.documentDate || ''), [doc?.documentDate]);

  const getDocName = useCallback(() => {
    // Money Collection case
    if (doc?.entity_type === CUSTOMER_REPORT_ENTITY_TYPE.MONEY_COLLECTION) {
      return `${t('CUSTOMERS_REPORT.MONEY_COLLECTION_DOC')} - ${t(`MONEY_COLLECTION_PAYMENT_TYPES.${doc?.paymentType}`)}`;
    }

    return t(`DOC_TYPES.${doc?.documentTypeName}`);
  }, [doc?.documentTypeName, doc?.entity_type, doc?.paymentType, t]);

  return (
    <div className="flex-start-start-row-nowrap padding-2 width-100 positionRelative">
      <div className="flex-start-start-column-nowrap padding-l-4 padding-r-4">
        <div className="productIndexSection">
          <div className="normalText1">{index + 1}.</div>
        </div>
        <div className="boldText1 width-80px padding-l-10">{docDate}</div>
      </div>
      <div className="flex-center-center-row-nowrap boldText1 width-100px">
        {doc?.seriesName && <div className="width-60px width-1line">{doc?.seriesName}</div>}
        <div>({doc?.serialNumber})</div>
      </div>
      <div className="boldText1 flex1 width-1line padding-l-18">{getDocName()}</div>
      <LineChargesSection doc={doc} />
      <LineSubtotalsSection doc={doc} />
    </div>
  );
};
