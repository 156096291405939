import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { pdfStyles } from '../../../../../components/molecules/pdf/pdfStyles';

export const CustomerReportTableHeaderPdf = () => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Text style={[pdfStyles.smallTitleRegular, styles.section, styles.center, pdfStyles.width60]}>
        {t('CUSTOMERS_REPORT.DATE')}
      </Text>
      <Text style={[pdfStyles.smallTitleRegular, styles.section, styles.center, pdfStyles.width80]}>
        {t('CUSTOMERS_REPORT.SERIES_NAME_NUMBER_HEADER')}
      </Text>
      <Text style={[pdfStyles.smallTitleRegular, styles.section, styles.center, styles.width163, { paddingLeft: 4 }]}>
        {t('CUSTOMERS_REPORT.DOCUMENT_TYPE')}
      </Text>
      <Text style={[pdfStyles.smallTitleRegular, styles.section, styles.center, pdfStyles.width60]}>
        {t('CUSTOMERS_REPORT.CUSTOMER_SUBTOTAL')}
      </Text>
      <Text style={[pdfStyles.smallTitleRegular, styles.section, styles.center, pdfStyles.width60]}>
        {t('CUSTOMERS_REPORT.TRADER_SUBTOTAL')}
      </Text>
      <Text style={[pdfStyles.smallTitleRegular, styles.section, styles.center, pdfStyles.width60]}>
        {t('CUSTOMERS_REPORT.CUSTOMER_SUBTOTAL')}
      </Text>
      <Text style={[pdfStyles.smallTitleRegular, styles.center, pdfStyles.width60]}>
        {t('CUSTOMERS_REPORT.TRADER_SUBTOTAL')}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    backgroundColor: '#c9cace',
    padding: 2,
  },
  section: {
    borderRightWidth: 1,
    borderColor: 'white',
  },
  center: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  width163: {
    width: 163,
  },
});
