import { IonButton, IonIcon, useIonAlert } from '@ionic/react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { sendSharp, shareSocialOutline } from 'ionicons/icons';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useDeleteSale } from '../../../api/hooks/sales/useDeleteSale';
import { useMutatePublicShare } from '../../../api/hooks/sales/useMutatePublicShare';
import { useSale } from '../../../api/hooks/sales/useSale';
import { useSendEmailToCustomer } from '../../../api/hooks/sales/useSendEmail';
import { usePostSale } from '../../../api/hooks/sales/useUpdateCreateSale';
import { useCompanyLogo } from '../../../api/hooks/useCompanyLogo';
import { Loading } from '../../../components/atoms/Loading';
import { DocView } from '../../../components/molecules/docView/DocView';
import { InvoicePdf } from '../../../components/molecules/pdf/InvoicePdf';
import { pdfStyles } from '../../../components/molecules/pdf/pdfStyles';
import { ENV } from '../../../config/env';
import { isAfter24Hours, nowDateInUtc } from '../../../config/utils/dateHelpers';
import { UNPOSTABLE_AADE_DOC_TYPE_CODES } from '../../../interfaces/enumerables';
import { PATHS } from '../../../navigation/data';
import { useHasActivePlan } from '../../../services/utils/useHasActivePlan';
import { selectIsCompanyOwner, selectSelectedCompany } from '../../../store/companies/companiesSlice';
import { setToast } from '../../../store/core/coreSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { SaleContextInterface, SaleScreenContextProvider } from './SaleScreenContext';

export const SaleWrapper = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { data, isLoading, refetch, isRefetching } = useSale(id);
  const hasActivePlan = useHasActivePlan();
  const [presentAlert] = useIonAlert();
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const isCompanyOwner = useAppSelector(selectIsCompanyOwner);
  const { doc, setDoc } = useContext<SaleContextInterface>(SaleScreenContextProvider);
  const { mutate: publicShareDoc } = useMutatePublicShare();
  const { isLoading: isLoadingMutation, mutate, isSuccess } = usePostSale();
  const { isLoading: isLoadingSendEmail, mutate: sendEmail } = useSendEmailToCustomer();
  const { data: logoData } = useCompanyLogo(data?.company?.logoUrl || selectedCompany?.logoUrl || '');
  const { mutate: deleteEntity, isLoading: isLoadingDelete } = useDeleteSale(history);
  const isDocPostable = useMemo(
    () => !UNPOSTABLE_AADE_DOC_TYPE_CODES.includes(data?.documentType?.aadeCode || ''),
    [data?.documentType?.aadeCode],
  );

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess, refetch]);

  useEffect(() => {
    if (data) {
      setDoc?.(data);
    }

    return () => {
      setDoc?.(null);
    };
  }, [data, setDoc]);

  const getLoading = useCallback(() => {
    if (isLoading || isLoadingDelete) {
      return <Loading backgroundFull />;
    }

    return null;
  }, [isLoading, isLoadingDelete]);

  const getLoadingPostDoc = useCallback(() => {
    if (isLoadingMutation || isLoadingSendEmail || isRefetching) {
      return <Loading />;
    }

    return null;
  }, [isLoadingMutation, isLoadingSendEmail, isRefetching]);

  const editDoc = useCallback(() => {
    history.push(`${PATHS.SALES}/edit/${id}`);
  }, [history, id]);

  const deleteConfirmation = useCallback(() => {
    presentAlert({
      mode: 'ios',
      header: t('FORMS.CONFIRM_DELETE_MSG'),
      buttons: [
        {
          text: t('SHARED.CANCEL'),
          role: 'cancel',
          cssClass: 'noTextTransformation',
        },
        {
          text: t('SHARED.CONFIRM'),
          role: 'confirm',
          handler: () => {
            // @ts-expect-error mutation ts error
            deleteEntity({ id });
          },
          cssClass: 'noTextTransformation',
        },
      ],
    });
  }, [presentAlert, t, deleteEntity, id]);

  const postDoc = useCallback(() => {
    if (data?.id) {
      // @ts-expect-error mutation ts error
      mutate({ entityId: data?.id });
    }
  }, [data?.id, mutate]);

  const postConfirmation = useCallback(() => {
    let msg = t('FORMS.CONFIRM_POST_MSG');
    const nowDate = nowDateInUtc();
    if (doc?.documentDate !== nowDate) msg = `${t('FORMS.CONFIRM_POST_MSG_DIFF_DATE')} ${msg}`;

    presentAlert({
      mode: 'ios',
      header: msg,
      buttons: [
        {
          text: t('SHARED.CANCEL'),
          role: 'cancel',
          cssClass: 'noTextTransformation',
        },
        {
          text: t('SHARED.CONFIRM'),
          role: 'confirm',
          handler: () => postDoc(),
          cssClass: 'noTextTransformation',
        },
      ],
    });
  }, [t, doc?.documentDate, presentAlert, postDoc]);

  const showInfoOnDownload = useCallback(() => {
    // TODO: remove check of aade code when post delivery note feature is implemented
    if (!data?.sentToAade && data?.documentType?.aadeCode !== '9.3') {
      dispatch(setToast({ value: { show: true, msg: t('FORMS.DOWNLOAD_INFO'), isError: false, duration: 10000 } }));
    }
  }, [data?.documentType?.aadeCode, data?.sentToAade, dispatch, t]);

  const downloadDoc = useCallback(() => {
    if (data?.id && data?.company && doc) {
      const fileName = `billease-${data?.customer?.companyName}-${data.documentDate}.pdf`;

      return (
        <PDFDownloadLink
          document={<InvoicePdf doc={data} logo={logoData} />}
          fileName={fileName}
          onClick={showInfoOnDownload}
          style={pdfStyles.ionButton}>
          {t('FORMS.DOWNLOAD_PDF')}
        </PDFDownloadLink>
      );
    }
  }, [data, doc, logoData, showInfoOnDownload, t]);

  const getDeleteButton = useCallback(() => {
    if (isCompanyOwner && hasActivePlan && !data?.sentToAade) {
      return (
        <IonButton mode="md" onClick={deleteConfirmation} fill="outline" className="ion-delete-button">
          {t('FORMS.DELETE')}
        </IonButton>
      );
    }
  }, [data?.sentToAade, deleteConfirmation, hasActivePlan, isCompanyOwner, t]);

  const shareDocPublicly = useCallback(() => {
    publicShareDoc({ entityId: id });

    const publicUrl = `${ENV.DOMAIN}share/doc/${id}`;
    navigator?.clipboard?.writeText(publicUrl);
    dispatch(
      setToast({ value: { show: true, msg: t('SHARED.COPIED_TO_CLIPBOARD'), isError: false, position: 'top' } }),
    );
  }, [dispatch, id, publicShareDoc, t]);

  const getShareDocButton = useCallback(
    () => (
      <IonButton mode="md" onClick={shareDocPublicly}>
        {t('SHARED.SHARE')}
        <IonIcon slot="end" icon={shareSocialOutline}></IonIcon>
      </IonButton>
    ),
    [t, shareDocPublicly],
  );

  const sendEmailToCustomer = useCallback(() => {
    if (id) {
      sendEmail({ entityId: id });
    }
  }, [id, sendEmail]);

  const canSendEmail = useCallback((emailSentAt: string | null | undefined) => {
    if (!emailSentAt) return true;

    return isAfter24Hours(emailSentAt);
  }, []);

  const getSendEmailButton = useCallback(() => {
    if (hasActivePlan && data?.customerEmail && canSendEmail(data?.emailSentToCustomerAt)) {
      return (
        <IonButton mode="md" onClick={sendEmailToCustomer}>
          {t('SHARED.SEND_EMAIL')}
          <IonIcon slot="end" icon={sendSharp}></IonIcon>
        </IonButton>
      );
    }
  }, [hasActivePlan, data?.customerEmail, data?.emailSentToCustomerAt, canSendEmail, sendEmailToCustomer, t]);

  const getPostButton = useCallback(() => {
    if (hasActivePlan && isDocPostable && !data?.sentToAade) {
      return (
        <IonButton mode="md" onClick={postConfirmation}>
          {t('FORMS.POST_TO_MY_DATA')}
        </IonButton>
      );
    }
  }, [data?.sentToAade, hasActivePlan, isDocPostable, postConfirmation, t]);

  const getEditButton = useCallback(() => {
    if (hasActivePlan && !data?.sentToAade) {
      return (
        <IonButton mode="md" onClick={editDoc}>
          {t('FORMS.EDIT')}
        </IonButton>
      );
    }
  }, [data?.sentToAade, editDoc, hasActivePlan, t]);

  const getActionButtons = useCallback(() => {
    return (
      <div className="padding-b-10 flex-space-between-start-row-wrap width-100">
        <div className="flex-start-center-row-wrap">
          {getEditButton()}
          {getPostButton()}
          {downloadDoc()}
          {getShareDocButton()}
        </div>
        <div className="flex-start-center-row-wrap">
          <div className="padding-r-10">{getSendEmailButton()}</div>
          {getDeleteButton()}
        </div>
      </div>
    );
  }, [getEditButton, getPostButton, downloadDoc, getShareDocButton, getSendEmailButton, getDeleteButton]);

  // const getPdf = useCallback(() => {
  //   if (data?.id && selectedCompany && doc) {
  //     return (
  //       <PDFViewer style={{ width: '100%', height: '1300px' }} showToolbar={true}>
  //         <InvoicePdf doc={data} logo={logoData} />
  //       </PDFViewer>
  //     );
  //   }
  // }, [data, selectedCompany, logoData, doc]);

  const getDocView = useCallback(() => {
    if (data?.id && data?.company) {
      return <DocView doc={data} logo={logoData} />;
    }
  }, [data, logoData]);

  return (
    <>
      {getLoading()}
      {getLoadingPostDoc()}
      <div className="width-100 padding-t-16 padding-b-16 flex-center-start-column-nowrap padding-r-24 padding-l-24">
        {getActionButtons()}
        <div className="scrollable-doc">{getDocView()}</div>
        {/*{getPdf()}*/}
      </div>
    </>
  );
};
