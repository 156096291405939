import { useSales } from '../../api/hooks/sales/useSales';
import { EntityPaginationData } from '../../components/molecules/EntityPaginationData';
import { SORT_BY_SALES, SORT_DIRECTION } from '../../interfaces/enumerables';
import { getSalesSortByFilter } from '../../services/utils/sortData';
import { SalesItemCard } from './components/SalesItemCard';

export const SalesScreen = () => {
  const salesSortByFilter = getSalesSortByFilter();

  return (
    <EntityPaginationData
      cssPostfix="380"
      useDataHook={useSales}
      ItemCardData={SalesItemCard}
      sortByFilter={salesSortByFilter}
      sortByDefaultKey={SORT_BY_SALES.CREATED_AT}
      sortDirectionValue={SORT_DIRECTION.DESC}
    />
  );
};
