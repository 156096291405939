import i18next from 'i18next';
import { useMemo } from 'react';
import * as yup from 'yup';
import { useCustomers } from '../../api/hooks/customers/useCustomers';
import { getFirstDayOfYearUtc, nowDate } from '../../config/utils/dateHelpers';
import { EntityFormInterface, INPUT_TYPE } from '../../interfaces/forms.inteface';
import { CustomerReport } from './components/customer/CustomerReport';

const getFormData = (): EntityFormInterface<any> => {
  return {
    fields: [
      {
        name: 'customerId',
        label: 'PICK_CUSTOMER',
        placeholder: 'CUSTOMER',
        value: null,
        fieldValue: 'companyName',
        fieldValue2: 'lastName',
        fieldToSubmit: 'id',
        fieldToSearch: 'vatNumber',
        // @ts-expect-error happens
        useHook: useCustomers,
        required: true,
        type: INPUT_TYPE.ANTD_SELECT_SEARCH,
      },
      {
        name: 'start',
        label: 'START_DATE',
        placeholder: 'START_DATE',
        value: getFirstDayOfYearUtc(),
        type: INPUT_TYPE.DATE,
      },
      {
        name: 'end',
        label: 'END_DATE',
        placeholder: 'END_DATE',
        value: nowDate(),
        type: INPUT_TYPE.DATE,
      },
      {
        name: 'includeMoneyCollection',
        label: 'INCLUDE_MONEY_COLLECTION',
        placeholder: 'INCLUDE_MONEY_COLLECTION',
        tooltip: 'INCLUDE_MONEY_COLLECTION_DESCR',
        value: false,
        type: INPUT_TYPE.TOGGLE,
      },
      {
        name: 'includeDeliveryNotes',
        label: 'INCLUDE_DELIVERY_NOTES',
        placeholder: 'INCLUDE_DELIVERY_NOTES',
        tooltip: 'INCLUDE_DELIVERY_NOTES_DESCR',
        value: true,
        type: INPUT_TYPE.TOGGLE,
      },
    ],
  };
};

const validationSchema = yup.object({
  customerId: yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
  start: yup.date().required(),
  end: yup
    .date()
    .required()
    .test(
      'same-year',
      i18next.t('API_ERRORS.START_END_DATES_MUST_BE_SAME_YEAR'), // Custom error message
      function (value) {
        const { start } = this.parent; // Get the start date from the parent object
        if (start && value) {
          const startYear = new Date(start).getFullYear();
          const endYear = new Date(value).getFullYear();
          return startYear === endYear; // Return true if the years match
        }
        return true; // If either `start` or `end` is not defined yet, validation passes
      },
    ),
  includeMoneyCollection: yup.boolean().notRequired(),
  includeDeliveryNotes: yup.boolean().notRequired(),
});

export const CustomerReportScreen = () => {
  const formData = useMemo(() => getFormData(), []);

  return (
    <div className="width-100 padding-t-16 padding-b-16 flex-start-center-column-nowrap padding-r-24 padding-l-24">
      <CustomerReport data={formData} validationSchema={validationSchema} />
    </div>
  );
};
