import { IonButton } from '@ionic/react';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BSelectSearch } from '../../../../components/molecules/BSelectSearch';
import { BToggle } from '../../../../components/molecules/BToggle';
import { DocDate } from '../../../../components/molecules/docForm/DocDate';
import { getCurrentYear, getLastDayOfYearMinus4HoursUtc, getYearOfDate } from '../../../../config/utils/dateHelpers';

interface Props {
  formik: any;
  customerField: any;
  includeMoneyCollectionField: any;
  startDateField: any;
  endDateField: any;
  includeDeliveryNotesField: any;
}

export const CustomerReportFilters = ({
  formik,
  customerField,
  includeMoneyCollectionField,
  startDateField,
  endDateField,
  includeDeliveryNotesField,
}: Props) => {
  const { t } = useTranslation();
  const currentYear = useMemo(() => getCurrentYear(), []);

  const startDateValue = useMemo(() => formik.values?.[startDateField?.name], [formik.values, startDateField?.name]);
  const startDateYear = useMemo(() => getYearOfDate(startDateValue), [startDateValue]);
  const endDateValue = useMemo(() => formik.values?.[endDateField?.name], [endDateField?.name, formik.values]);
  const endDateYear = useMemo(() => getYearOfDate(endDateValue), [endDateValue]);

  const maxDateOfEndDate = useMemo(() => {
    const lastDayOfYear = getLastDayOfYearMinus4HoursUtc(startDateYear);
    if (startDateYear < currentYear) return dayjs(lastDayOfYear);

    return dayjs();
  }, [currentYear, startDateYear]);

  useEffect(() => {
    if (startDateYear && startDateYear !== endDateYear) {
      formik.setFieldValue(endDateField?.name, maxDateOfEndDate.toISOString());
    }
  }, [endDateField?.name, endDateYear, formik, maxDateOfEndDate, startDateValue, startDateYear]);

  return (
    <div className="flex-center-center-column-nowrap">
      <form onSubmit={formik.handleSubmit}>
        <div className="padding-t-0 padding-b-24 padding-l-8 padding-r-8 flex-center-center-row-wrap flex-gap-10">
          <BSelectSearch field={customerField} useHook={customerField.useHook} formik={formik} focusOnMount />
          <DocDate field={startDateField} formik={formik} title={`${t('FORMS.START_DATE')} *`} maxDate={dayjs()} />
          <DocDate
            field={endDateField}
            formik={formik}
            title={`${t('FORMS.END_DATE')} *`}
            minDate={startDateValue}
            maxDate={maxDateOfEndDate}
          />
          <BToggle field={includeMoneyCollectionField} formik={formik} />
          <BToggle field={includeDeliveryNotesField} formik={formik} />
        </div>
        <div className="flex-center-center-row-wrap padding-t-10">
          <IonButton mode="md" type="submit" disabled={!formik.isValid || !formik.dirty}>
            {t('FORMS.APPLY')}
          </IonButton>
        </div>
      </form>
    </div>
  );
};
