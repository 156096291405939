import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getFullDateTime } from '../../../../../config/utils/dateHelpers';
import { Company } from '../../../../../interfaces/company.interface';
import { Customer } from '../../../../../interfaces/customer.interface';
import { CustomerReportResponse } from '../../../../../interfaces/reports.interface';
import { CustomerReportView } from './CustomerReportView';

interface Props {
  company: Company;
  customer: Customer;
  data: CustomerReportResponse;
}

export const CustomerReportTable = ({ company, customer, data }: Props) => {
  const { t } = useTranslation();

  const getCustomerSection = useCallback(() => {
    return (
      <div className="flex-space-between-center-row-wrap width-100 padding-t-10 padding-b-10">
        <div className="flex-start-center-column-nowrap">
          <div className="boldText3">{t('PDF.TRADER')}</div>
          <div className="normalText3">{customer?.companyName}</div>
        </div>
        <div className="flex-end-center-column-nowrap">
          <div className="boldText3">{t('PDF.VAT_NUMBER')}</div>
          <div className="normalText3">{customer?.vatNumber}</div>
        </div>
      </div>
    );
  }, [customer?.companyName, customer?.vatNumber, t]);

  return (
    <div className="margin-b-80">
      <div className="a4-container-only-width">
        <div className="section">
          <div>
            <div className="flex-center-center-column-nowrap">
              <div className="normalText3 padding-t-10">
                {company?.title} - {t('CUSTOMERS_REPORT.VAT_NUMBER')}: {company?.vatNumber}
              </div>
              <div className="normalText1 padding-t-10">{getFullDateTime(new Date().toISOString())}</div>
              {getCustomerSection()}
            </div>
            <div className="padding-t-10">
              <CustomerReportView data={data} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
