import Decimal from 'decimal.js';
import { MONEY_COLLECTION_PAYMENT_TYPE, MONEY_COLLECTION_TRADER } from '../../api/hooks/useFormMoneyCollection';
import { Customer } from '../../interfaces/customer.interface';

const DECIMAL_OPERATION = {
  ADD: 'plus',
  MINUS: 'minus',
} as const;

export type DecimalOperationType = (typeof DECIMAL_OPERATION)[keyof typeof DECIMAL_OPERATION];

const POSITIVE_OPERATION_PAYMENT_TYPES = [
  MONEY_COLLECTION_PAYMENT_TYPE.CASH,
  MONEY_COLLECTION_PAYMENT_TYPE.BUSINESS_BANK_ACCOUNT,
  MONEY_COLLECTION_PAYMENT_TYPE.MONEY_CHECK,
];
export const NEGATIVE_OPERATION_PAYMENT_TYPES = [
  MONEY_COLLECTION_PAYMENT_TYPE.RETURN_MONEY_CASH,
  MONEY_COLLECTION_PAYMENT_TYPE.RETURN_MONEY_BANK,
  MONEY_COLLECTION_PAYMENT_TYPE.RETURN_MONEY_CHECK,
];

export const calculateNewSubtotalOfExistingDocUtility = (
  paymentType: MONEY_COLLECTION_PAYMENT_TYPE,
  currentSubtotal: string,
  value: number | string,
): Decimal => {
  const decimalValue = new Decimal(value);
  const operation = getMathOperation(paymentType);
  const subtotal = new Decimal(currentSubtotal);
  return subtotal?.[operation]?.(decimalValue);
};

export const calculateNewSubtotalUtility = (
  traderType: MONEY_COLLECTION_TRADER,
  paymentType: MONEY_COLLECTION_PAYMENT_TYPE,
  customer: Customer,
  value: number | string,
): Decimal => {
  const decimalValue = new Decimal(value);
  const operation = getMathOperation(paymentType);
  const subtotal = getTraderSubtotal(traderType, customer);
  return subtotal?.[operation]?.(decimalValue);
};

export const getTraderSubtotal = (traderType: MONEY_COLLECTION_TRADER, customer: Customer): Decimal => {
  let subtotal = customer?.customerSubtotal || 0;
  if (traderType === MONEY_COLLECTION_TRADER.SUPPLIER) {
    subtotal = customer?.supplierSubtotal || 0;
  }

  return new Decimal(subtotal);
};

/**
 * Same business logic for both types of trader for now
 * MONEY_COLLECTION_TRADER.CUSTOMER | MONEY_COLLECTION_TRADER.SUPPLIER
 * If we have clearing we are going to `subtract` the amount for the subtotal of the trader in the view
 * In the BE we are also going to `subtract` for the other subtotal of trader that amount
 * If positive operation always `subtract`
 * Otherwise `add`
 * @param paymentType
 */
const getMathOperation = (paymentType: MONEY_COLLECTION_PAYMENT_TYPE): DecimalOperationType => {
  if (paymentType === MONEY_COLLECTION_PAYMENT_TYPE.CLEARING) {
    return DECIMAL_OPERATION.MINUS;
  }

  if (POSITIVE_OPERATION_PAYMENT_TYPES.includes(paymentType)) {
    return DECIMAL_OPERATION.MINUS;
  }

  return DECIMAL_OPERATION.ADD;
};
