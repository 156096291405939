import { IonButton, IonButtons, IonIcon, useIonAlert } from '@ionic/react';
import { createOutline } from 'ionicons/icons';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useCustomer } from '../../api/hooks/customers/useCustomer';
import { useDeleteCustomer } from '../../api/hooks/customers/useMutateCustomer';
import { Loading } from '../../components/atoms/Loading';
import { ViewField } from '../../components/atoms/ViewField';
import { CURRENCY, SUBSCRIPTION } from '../../interfaces/enumerables';
import { PATHS } from '../../navigation/data';
import { useHasActivePlan } from '../../services/utils/useHasActivePlan';
import { usePlanIsActive } from '../../services/utils/usePlanIsActive';

export const CustomerScreen = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const showSubtotals = usePlanIsActive(SUBSCRIPTION.SUBTOTALS);
  const hasActivePlan = useHasActivePlan();
  const { data, isLoading } = useCustomer(id);
  const {
    mutate: deleteEntity,
    isLoading: isDeleteLoading,
    isSuccess: isDeleteSuccess,
    data: deleteResp,
  } = useDeleteCustomer();
  const [presentAlert] = useIonAlert();

  useEffect(() => {
    if (isDeleteSuccess) {
      if (deleteResp?.success) {
        history.replace(PATHS.CUSTOMERS);
      }
    }
  }, [isDeleteSuccess]);

  const navigateToMoneyCollection = useCallback(() => {
    history.push(`${PATHS.MONEY_COLLECTION}/create`, { customer: data });
  }, [history, data?.id]);

  const editDoc = useCallback(() => {
    history.push(`${PATHS.CUSTOMERS}/${id}`);
  }, [history, id]);

  const getLoading = useCallback(() => {
    if (isLoading || isDeleteLoading) {
      return <Loading backgroundFull />;
    }

    return null;
  }, [isLoading, isDeleteLoading]);

  const deleteConfirmation = useCallback(() => {
    presentAlert({
      mode: 'ios',
      header: t('FORMS.CONFIRM_DELETE_MSG'),
      buttons: [
        {
          text: t('SHARED.CANCEL'),
          role: 'cancel',
          cssClass: 'noTextTransformation',
        },
        {
          text: t('SHARED.CONFIRM'),
          role: 'confirm',
          handler: () => {
            // @ts-expect-error ts error from lib
            deleteEntity({ id });
          },
          cssClass: 'noTextTransformation',
        },
      ],
    });
  }, [presentAlert, t, id]);

  const getDeleteButton = useCallback(() => {
    if (hasActivePlan && data?.canDelete) {
      return (
        <IonButton mode="md" onClick={deleteConfirmation} fill="outline" className="ion-delete-button">
          {t('FORMS.DELETE')}
        </IonButton>
      );
    }
  }, [hasActivePlan, deleteConfirmation, data?.canDelete]);

  const getRedirectToMoneyCollectionElement = useCallback(() => {
    return (
      <IonButtons>
        <IonButton onClick={navigateToMoneyCollection} color="transparent">
          <IonIcon slot="icon-only" icon={createOutline} color="tertiary" />
        </IonButton>
      </IonButtons>
    );
  }, [navigateToMoneyCollection]);

  const getActionButtons = useCallback(() => {
    return (
      <div className="padding-b-10 flex-space-between-start-row-wrap width-100">
        <div className="flex-start-center-row-wrap">
          {hasActivePlan && (
            <IonButton mode="md" onClick={editDoc}>
              {t('FORMS.EDIT')}
            </IonButton>
          )}
        </div>
        {getDeleteButton()}
      </div>
    );
  }, [hasActivePlan, editDoc, t, getDeleteButton]);

  const getSubtotals = useCallback(() => {
    if (showSubtotals) {
      return (
        <>
          <ViewField
            label="FORMS.CUSTOMER_SUBTOTAL"
            value={`${data?.customerSubtotal} (${CURRENCY[data?.currency?.name]})`}
            actionElement={getRedirectToMoneyCollectionElement()}
          />
          <ViewField
            label="FORMS.SUPPLIER_SUBTOTAL"
            value={`${data?.supplierSubtotal} (${CURRENCY[data?.currency?.name]})`}
            actionElement={getRedirectToMoneyCollectionElement()}
          />
        </>
      );
    }
  }, [
    showSubtotals,
    data?.customerSubtotal,
    data?.currency?.name,
    data?.supplierSubtotal,
    getRedirectToMoneyCollectionElement,
  ]);

  const getAddressValue = useCallback(() => {
    return `${data?.address || ' - '}, ${data?.city || ' - '}, ${data?.postalCode || ' - '}`;
  }, [data?.address, data?.city, data?.postalCode]);

  const getMainSection = useCallback(() => {
    if (data && !isLoading) {
      return (
        <div className="docForm margin-t-12">
          <div className="padding-t-24 padding-b-24 padding-l-8 padding-r-8 flex-start-center-row-wrap flex-gap-30">
            <ViewField label="FORMS.CUSTOMER" value={data?.companyName} />
            <ViewField label="FORMS.VAT_NUMBER" value={data?.vatNumber} />
            <ViewField label="FORMS.ADDRESS" value={getAddressValue()} />
            <ViewField label="FORMS.PHONE_NUMBER" value={data.phoneNumber || ' - '} />
            <ViewField label="FORMS.EMAIL" value={data.email || ' - '} />
            {getSubtotals()}
          </div>
        </div>
      );
    }
  }, [data, getSubtotals, isLoading, getAddressValue]);

  return (
    <div className="width-100 flex-center-center-column-nowrap padding-b-60">
      {getLoading()}
      <div className="page flex-start-center-column-nowrap">
        {getActionButtons()}
        {getMainSection()}
      </div>
    </div>
  );
};
