import { IonToggle } from '@ionic/react';
import i18next from 'i18next';
import { useCallback } from 'react';
import { TooltipInInput } from './TooltipInInput';

interface Props {
  tooltip?: string;
  label: string;
  checked: boolean;
  clickCallback: () => void;
}

export const BToggleNotInForm = ({ tooltip, label, checked, clickCallback }: Props) => {
  const getTooltip = useCallback(() => {
    if (tooltip) {
      return <TooltipInInput value={tooltip} />;
    }
  }, [tooltip]);

  return (
    <div className="width-300px">
      <div className="g-toggle-container height-50px b-field-border border-radius-input positionRelative">
        {getTooltip()}
        <div className="sc-ion-label-md-s normalText4 border-radius-24">{i18next.t(label)}</div>
        <IonToggle
          name="simple-tooltip"
          mode="ios"
          checked={checked}
          className="border-radius-24"
          onClick={clickCallback}></IonToggle>
      </div>
    </div>
  );
};
