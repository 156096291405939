import { useProducts } from '../../api/hooks/products/useProducts';
import { EntityPaginationData } from '../../components/molecules/EntityPaginationData';
import { SORT_BY_PRODUCTS } from '../../interfaces/enumerables';
import { getProductsSortByFilter } from '../../services/utils/sortData';
import { ProductItemCard } from './components/ProductItemCard';

export const ProductsScreen = () => {
  const productsSortByFilter = getProductsSortByFilter();

  return (
    <EntityPaginationData
      cssPostfix="380"
      useDataHook={useProducts}
      ItemCardData={ProductItemCard}
      sortByFilter={productsSortByFilter}
      sortByDefaultKey={SORT_BY_PRODUCTS.INTERNAL_SERIAL}
    />
  );
};
