import { useMoneyCollectionList } from '../../api/hooks/moneyCollection/useMoneyCollectionList';
import { EntityPaginationData } from '../../components/molecules/EntityPaginationData';
import { SORT_BY_MONEY_COLLECTION, SORT_DIRECTION, SUBSCRIPTION } from '../../interfaces/enumerables';
import { getMoneyCollectionByFilter } from '../../services/utils/sortData';
import { useHasActivePlan } from '../../services/utils/useHasActivePlan';
import { MoneyCollectionItemCard } from './components/MoneyCollectionItemCard';

export const MoneyCollectionListScreen = () => {
  useHasActivePlan(true, SUBSCRIPTION.SUBTOTALS);
  const moneyCollectionSortByFilter = getMoneyCollectionByFilter();

  return (
    <EntityPaginationData
      cssPostfix="380"
      useDataHook={useMoneyCollectionList}
      ItemCardData={MoneyCollectionItemCard}
      sortByFilter={moneyCollectionSortByFilter}
      sortByDefaultKey={SORT_BY_MONEY_COLLECTION.CREATED_AT}
      sortDirectionValue={SORT_DIRECTION.DESC}
    />
  );
};
