import { useQuery } from 'react-query';
import { Customer } from '../../../interfaces/customer.interface';
import { SORT_BY_CUSTOMERS } from '../../../interfaces/enumerables';
import { apiRQ } from '../../api';

const getCustomersSubtotalsReport = async (params: { orderBy: string; order: string }): Promise<Customer[]> => {
  const resp = await apiRQ.get<Customer[]>('/reports/customers/', { params });

  return resp.data;
};

export const useCustomersSubtotalsReport = (sortBy = SORT_BY_CUSTOMERS.CUSTOMER_SUBTOTAL, order = 'ASC') => {
  return useQuery<Customer[], Error>(
    ['useCustomersReport', sortBy, order],
    () => getCustomersSubtotalsReport({ orderBy: sortBy, order }),
    {
      cacheTime: 6000,
      staleTime: 6000,
    },
  );
};

export const selectCustomersReportData = (data: Customer[], includeZeroSubtotals: boolean) => {
  if (includeZeroSubtotals) return data;

  const filteredCustomers = [];

  for (let i = 0; i < data?.length; i++) {
    if (data[i]?.customerSubtotal !== '0.00' || data[i]?.supplierSubtotal !== '0.00') {
      filteredCustomers.push(data[i]);
    }
  }

  return filteredCustomers;
};
