import { Network } from '@capacitor/network';
import axios, { AxiosRequestConfig } from 'axios';
import { AxiosError } from 'axios/index';
import i18n from 'i18next';
import { getSelectedCompanyId, getUserStorage } from '../services/localStorage.service';
import { setToast } from '../store/core/coreSlice';
import { store } from '../store/store';
import { logoutUserAsync } from '../store/user/userActions';

export const onFulfilled = async (config: AxiosRequestConfig): Promise<any> => {
  const deviceData = {};
  const userData = await getUserStorage();
  const selectedCompanyId = await getSelectedCompanyId();
  // TODO: check if I should add here the selected company id
  // const deviceData = {
  //     appVersion: appVersionString,
  //     platform: `${Platform.OS} ${Platform.Version}`,
  //     userId: userData?.user?.id,
  //     userType: 'customer',
  // };

  if (config.method !== 'OPTIONS' && config.headers) {
    // Use only on dev
    // config.headers['ngrok-skip-browser-warning'] = '69420';
    if (userData) {
      // If token is found add it to the header
      config.headers['authorization'] = 'JWT ' + (userData?.jwt || '');
      config.headers['companyId'] = selectedCompanyId || '';
    }
    config.headers.logInfo = JSON.stringify(deviceData);
  }

  return config;
};

export const netCheck = async (config: AxiosRequestConfig): Promise<any> => {
  const state = await Network.getStatus();
  if (!state?.connected) {
    throw new axios.Cancel('ERRORS.NO_INTERNET');
  }
  return config;
};

export const onRejected = (error: Error) => {
  return Promise.reject(error);
};

export const onRejectedHandled = (err: AxiosError & Record<string, any>) => {
  // @ts-expect-error axios interface
  const error = err?.response?.data?.error;

  if (err?.response?.status === 401) {
    // @ts-expect-error axios interface
    const msg = error || i18n.t(`API_ERRORS.${err?.response?.data?.message || 'SOMETHING_WENT_WRONG'}`);
    store.dispatch(logoutUserAsync());
    store.dispatch(
      setToast({
        value: {
          show: true,
          msg,
          isError: true,
        },
      }),
    );
    return { data: { thrownError: true } };
  }

  if (err?.response?.status === 404) {
    store.dispatch(
      setToast({
        value: {
          show: true,
          msg: error || i18n.t('API_ERRORS.SOMETHING_WENT_WRONG'),
          isError: true,
        },
      }),
    );
    return { data: { thrownError: true } };
  }

  if (error) {
    store.dispatch(
      setToast({
        value: {
          show: true,
          msg: error || i18n.t('API_ERRORS.SOMETHING_WENT_WRONG'),
          isError: true,
        },
      }),
    );
    return { data: { thrownError: true } };
  }

  // Request cancelled by code (Case 1: NO_INTERNET)
  if (err?.message && !err?.response?.data) {
    store.dispatch(
      setToast({
        value: {
          show: true,
          msg: error || i18n.t('API_ERRORS.SOMETHING_WENT_WRONG'),
          isError: true,
        },
      }),
    );
    return { data: { thrownError: true } };
  }

  store.dispatch(
    setToast({
      value: { show: true, msg: i18n.t('API_ERRORS.SOMETHING_WENT_WRONG_CONTACT_ADMIN'), isError: true },
    }),
  );
  return { data: { thrownError: true } };
};
