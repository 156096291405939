import { IonButton } from '@ionic/react';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutationResult } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AnyObject } from 'yup';
import { useCreateUpdateSale } from '../../../api/hooks/sales/useUpdateCreateSale';
import {
  addDefaultZeroToPrices,
  cleanProducts,
  getChangedFields,
  removeIdsFromObjects,
} from '../../../config/utils/formDiffUtils';
import { formDataToSubmit, getInitialFormData } from '../../../config/utils/formUtils';
import { GenericResponse } from '../../../interfaces/shared.interface';
import { PATHS } from '../../../navigation/data';
import { isObjectEmpty } from '../../../services/utils/helpers';
import { setToast } from '../../../store/core/coreSlice';
import { Loading } from '../../atoms/Loading';
import { CustomerForm } from './CustomerForm';
import { DocFooter } from './DocFooter';
import { DocMainInfoSection } from './DocMainInfoSection';
import { DocPaymentDelivery } from './DocPaymentDelivery';
import { DocProducts } from './DocProducts';

interface Props {
  data: any;
  validationSchema: AnyObject;
  submitHook?: () => UseMutationResult<GenericResponse, Error, void, unknown>;
  deleteHook?: () => UseMutationResult<GenericResponse, Error, void, unknown>;
  isClone?: boolean;
}

export const DocForm = ({ data, validationSchema, isClone }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mutate: createModel, isLoading: isLoadingMutate, isSuccess, data: resp } = useCreateUpdateSale();
  const initialFormData = useMemo(() => getInitialFormData(data), [data]);

  useEffect(() => {
    if (isSuccess) {
      if (resp?.success) {
        if (resp?.entity?.id) {
          history.replace(`${PATHS.SALES_VIEW}/${resp?.entity?.id}`);
        } else {
          history.goBack();
        }
      }
      dispatch(
        setToast({
          value: { show: true, msg: resp?.error || t('SHARED.UPDATE_SUCCESSFUL'), isError: !resp?.success || false },
        }),
      );
    }
  }, [isSuccess]);

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema,
    onSubmit: (values) => {
      // console.log('==================================');
      // console.log('initialFormData: ', initialFormData);
      // console.log('values: ', values);
      let dataToSubmit: Record<string, any> = JSON.parse(JSON.stringify(values));
      addDefaultZeroToPrices(dataToSubmit.products);
      // console.log('dataToSubmit 2: ', dataToSubmit);
      // return;

      if (data.id && !isClone) {
        const changedFields = getChangedFields(data.fields, initialFormData, dataToSubmit);

        // Nothing changed, triggered info message
        if (isObjectEmpty(changedFields)) {
          dispatch(setToast({ value: { show: true, msg: t('SHARED.NO_CHANGES'), isError: false } }));
          return;
        }

        dataToSubmit = {
          id: data.id,
          ...changedFields,
        };

        if (dataToSubmit.products) {
          dataToSubmit.products = cleanProducts(initialFormData.products, dataToSubmit.products);
        }
      }

      // Remove ids from products if clone
      if (isClone) {
        dataToSubmit.products = removeIdsFromObjects(dataToSubmit.products);
      }
      // return;

      const finalData = formDataToSubmit(data.fields, dataToSubmit);

      // console.log('DocForm finalData: ', finalData);
      // return;

      // @ts-expect-error mutation interface
      createModel({ ...finalData, isClone });
    },
  });

  // useEffect(() => {
  //   console.log('====== formik.errors: ', formik.errors);
  //   console.log('====== formik.values: ', formik.values);
  // }, [formik.errors, formik.values]);

  const isProductsSectionDisabled = useMemo(() => !!formik?.errors?.customerId, [formik?.errors]);

  const getProductsSectionDisabledOverlay = useCallback(() => {
    if (isProductsSectionDisabled) {
      return <div className="disabled-section"></div>;
    }
  }, [isProductsSectionDisabled]);

  return (
    <div className="scrollable padding-b-10">
      {isLoadingMutate && <Loading />}
      <form onSubmit={formik.handleSubmit}>
        <div className="padding-16">
          <div className="margin-b-16">
            <CustomerForm
              field={data.fields[0]}
              vatRegimeField={data.fields[1]}
              vatExceptionCategoryField={data.fields[2]}
              formik={formik}
            />
          </div>
          <div className="margin-b-16">
            <DocMainInfoSection
              type={data.fields[3]}
              purposeField={data.fields[4]}
              date={data.fields[5]}
              paymentField={data.fields[11]}
              seriesName={data.fields[8]}
              serialNumber={data.fields[9]}
              formik={formik}
              isClone={isClone}
            />
          </div>
          <div className="margin-b-16 positionRelative">
            {getProductsSectionDisabledOverlay()}
            <DocProducts
              field={data.fields[10]}
              formik={formik}
              isSectionDisabled={isProductsSectionDisabled}
              taxWithholdField={data.fields[17]}
            />
          </div>
          <div className="margin-b-16">
            <DocPaymentDelivery
              shippingField={data.fields[12]}
              dueDate={data.fields[6]}
              shipDate={data.fields[7]}
              shipFrom={data.fields[13]}
              shipTo={data.fields[14]}
              shipToCity={data.fields[18]}
              shipToPostalCode={data.fields[19]}
              formik={formik}
            />
          </div>
          <div className="margin-b-16">
            <DocFooter field={data.fields[15]} formik={formik} />
          </div>
        </div>
        <div className="flex-center-center-row-wrap">
          <IonButton mode="md" type="submit" disabled={!formik.isValid || !formik.dirty} className="button-focused">
            {t('FORMS.SAVE')}
          </IonButton>
        </div>
      </form>
    </div>
  );
};
