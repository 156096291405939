import { View } from '@react-pdf/renderer';
import { pdfStyles } from '../../../../../components/molecules/pdf/pdfStyles';
import { Customer } from '../../../../../interfaces/customer.interface';
import { CustomersReportTableHeaderPdf } from './CustomersReportTableHeaderPdf';
import { CustomersReportTableListPdf } from './CustomersReportTableListPdf';
import { CustomersTableTotalsPdf } from './CustomersTableTotalsPdf';

interface Props {
  customers: Customer[];
}

export const CustomersReportTableSectionPdf = ({ customers }: Props) => {
  return (
    <View style={pdfStyles.column}>
      <View>
        <CustomersReportTableHeaderPdf />
      </View>
      <View>
        <CustomersReportTableListPdf customers={customers} />
        <CustomersTableTotalsPdf customers={customers} />
      </View>
    </View>
  );
};
