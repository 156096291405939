import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { availableLanguagesObj, ILanguage } from '../../config/i18n/common';
import { LoggedInUser } from '../../interfaces/auth.interface';
import { SetBooleanPayload, SetStringPayload } from '../../interfaces/store.interface';
import { SetCurrentLanguagePayload } from '../core/coreSlice.interface';
import { SetUserPayload, UserSlice, UserState } from './userSlice.interface';

const initialState: UserState = {
  loading: false,
  user: null,
  countryCode: '+30',
  currentLanguage: availableLanguagesObj.el,
  langLoading: false,
  logoutLoading: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserLoading: (state: UserState, action: PayloadAction<SetBooleanPayload>): void => {
      state.loading = action.payload.value;
    },
    setLangLoading: (state: UserState, action: PayloadAction<SetBooleanPayload>): void => {
      state.langLoading = action.payload.value;
    },
    setLogoutLoading: (state: UserState, action: PayloadAction<SetBooleanPayload>): void => {
      state.logoutLoading = action.payload.value;
    },
    setUser: (state: UserState, action: PayloadAction<SetUserPayload>): void => {
      state.user = action.payload.user;
    },
    setCountryCode: (state: UserState, action: PayloadAction<SetStringPayload>): void => {
      state.countryCode = action.payload.value;
    },
    setCurrentLanguage: (state: UserState, action: PayloadAction<SetCurrentLanguagePayload>): void => {
      state.currentLanguage = action.payload.currentLanguage;
    },
  },
});

//////////////////////////////// ACTIONS ////////////////////////////////
export const { setUser, setCountryCode, setCurrentLanguage, setLangLoading, setUserLoading, setLogoutLoading } =
  userSlice.actions;

//////////////////////////////// SELECTORS ////////////////////////////////
export const selectUserLoading = (state: UserSlice): boolean => state.user.loading;
export const selectLogoutLoading = (state: UserSlice): boolean => state.user.logoutLoading;
export const selectLangLoading = (state: UserSlice): boolean => state.user.langLoading;
export const selectCurrentLanguage = (state: UserSlice): ILanguage => state.user.currentLanguage;
export const selectShowMainNav = (state: UserSlice): boolean => !!state.user.user;
export const selectUser = (state: UserSlice): LoggedInUser | null => state.user.user;
export const selectUserFullName = (state: UserSlice): string =>
  `${state.user.user?.user?.lastName} ${state.user.user?.user?.firstName}`;
export const selectUserIsLoggedIn = (state: UserSlice): boolean => !!state.user.user;
export const selectCountryCode = (state: UserSlice): string => state.user.countryCode;

export default userSlice.reducer;
