import { View } from '@react-pdf/renderer';
import { pdfStyles } from '../../../../../components/molecules/pdf/pdfStyles';
import { CustomerReportResponse } from '../../../../../interfaces/reports.interface';
import { CustomerReportTableHeaderPdf } from './CustomerReportTableHeaderPdf';
import { CustomerReportTableListPdf } from './CustomerReportTableListPdf';
import { CustomerTableTotalsPdf } from './CustomerTableTotalsPdf';

interface Props {
  data: CustomerReportResponse;
}

export const CustomerReportTableSectionPdf = ({ data }: Props) => {
  return (
    <View style={pdfStyles.column}>
      <View>
        <CustomerReportTableHeaderPdf />
      </View>
      <View>
        <CustomerReportTableListPdf data={data} />
        <CustomerTableTotalsPdf data={data} />
      </View>
    </View>
  );
};
