import axios, { AxiosResponse } from 'axios';
import i18next from 'i18next';
import { ENV } from '../config/env';
import { setToast } from '../store/core/coreSlice';
import { store } from '../store/store';
import { netCheck, onFulfilled, onRejected, onRejectedHandled } from './middleware';

export const api = axios.create({
  baseURL: ENV.API_URL,
});

export const apiRQ = axios.create({
  baseURL: ENV.API_URL,
});

api.interceptors.request.use(onFulfilled, onRejected);

apiRQ.interceptors.request.use(onFulfilled, onRejected);

// Cancel request if there is no internet
api.interceptors.request.use(netCheck, onRejected);
apiRQ.interceptors.request.use(netCheck, onRejected);

// Add a response interceptor for errors
api.interceptors.response.use((response: AxiosResponse) => {
  const msg = i18next.t(`API_ERRORS.${response?.data?.errorCode || 'SOMETHING_WENT_WRONG'}`) || response.data.error;
  if ((response?.data?.errorCode || response?.data?.error) && !response.data.success) {
    store.dispatch(setToast({ value: { show: true, msg, isError: true } }));
  }

  return response;
}, onRejectedHandled);

apiRQ.interceptors.response.use((response: AxiosResponse) => {
  if (response?.data?.success) return response;

  if (response?.data?.errorCode) {
    store.dispatch(
      setToast({ value: { show: true, msg: i18next.t(`API_ERRORS.${response?.data?.errorCode}`), isError: true } }),
    );
    return response;
  }

  if (response?.data?.error) {
    store.dispatch(setToast({ value: { show: true, msg: response?.data?.error, isError: true } }));
  }

  return response;
}, onRejectedHandled);
