import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PdfDownloadLinkExtended } from '../../../../components/molecules/pdf/PdfDownloadLinkExtended';
import { nowDate } from '../../../../config/utils/dateHelpers';
import { Company } from '../../../../interfaces/company.interface';
import { Customer } from '../../../../interfaces/customer.interface';
import { CustomerReportResponse } from '../../../../interfaces/reports.interface';
import { selectSelectedCompany } from '../../../../store/companies/companiesSlice';
import { useAppSelector } from '../../../../store/hooks';
import { CustomerReportPdf } from './pdf/CustomerReportPdf';

interface Props {
  company: Company;
  logo: string | undefined | null;
  trader: Customer;
  data: CustomerReportResponse;
}

export const CustomerReportDownloadButton = ({ company, logo, trader, data }: Props) => {
  const { t } = useTranslation();
  const selectedCompany = useAppSelector(selectSelectedCompany);

  const downloadDoc = useCallback(() => {
    if (selectedCompany) {
      const fileName = `${selectedCompany?.title}_${t('CUSTOMERS_REPORT.DOWNLOAD_CUSTOMER_REPORT').replaceAll(' ', '')}_${nowDate()}.pdf`;

      return (
        <PdfDownloadLinkExtended
          document={<CustomerReportPdf company={company} logo={logo} trader={trader} data={data} />}
          fileName={fileName}
          text={t('FORMS.DOWNLOAD_PDF')}
        />
      );
    }
  }, [selectedCompany, t, company, logo, trader, data]);

  return <>{downloadDoc()}</>;
};
