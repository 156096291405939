import { CustomerReportDoc } from '../../interfaces/reports.interface';
import { addWithDecimals, minusWithDecimals, multiplyWithDecimals } from './calcUtils';
import { AADE_CODES_OF_RETURN_MONEY_DOCS, DOCUMENT_TYPE_AADE_CODE } from './constants';
import { NEGATIVE_OPERATION_PAYMENT_TYPES } from './moneyCollectionUtils';

interface ReportData {
  rawValue: string;
  currentPosSubtotal: string;
  currentNegSubtotal: string;
  isPostedDoc?: boolean;
  isDeliveryNote?: boolean;
}

export const getNewSubtotalUtility = (doc: CustomerReportDoc): ReportData => {
  // Actual doc
  if (doc?.documentAadeCode) {
    // For delivery no extra calculations are needed
    if (doc?.documentAadeCode === DOCUMENT_TYPE_AADE_CODE.DELIVERY_NOTE) {
      return {
        rawValue: doc?.currentSubtotal,
        currentPosSubtotal: doc?.currentSubtotal,
        currentNegSubtotal: '',
        isPostedDoc: true,
        isDeliveryNote: true,
      };
    }

    // @ts-expect-error no issue
    if (AADE_CODES_OF_RETURN_MONEY_DOCS.includes(doc?.documentAadeCode)) {
      const newSubtotal = minusWithDecimals(doc?.currentSubtotal || 0, doc?.totalPayable || 0);
      return { ...getResultWithProperSign(newSubtotal), isPostedDoc: true, isDeliveryNote: false };
    }

    const newSubtotal = addWithDecimals(doc?.currentSubtotal || 0, doc?.totalPayable || 0);
    return { ...getResultWithProperSign(newSubtotal), isPostedDoc: true, isDeliveryNote: false };
  } else {
    // Money collection
    // @ts-expect-error no issue
    if (NEGATIVE_OPERATION_PAYMENT_TYPES.includes(doc?.paymentType || '')) {
      const newSubtotal = addWithDecimals(doc?.currentSubtotal || 0, doc?.totalValue || 0);
      return { ...getResultWithProperSign(newSubtotal), isPostedDoc: false, isDeliveryNote: false };
    }
    const newSubtotal = minusWithDecimals(doc?.currentSubtotal || 0, doc?.totalValue || 0);
    return { ...getResultWithProperSign(newSubtotal), isPostedDoc: false, isDeliveryNote: false };
  }
};

const getResultWithProperSign = (subtotal: string): ReportData => {
  if (Number(subtotal) >= 0) {
    return { rawValue: subtotal, currentPosSubtotal: subtotal, currentNegSubtotal: '' };
  } else {
    return { rawValue: subtotal, currentPosSubtotal: '', currentNegSubtotal: multiplyWithDecimals(subtotal, -1) };
  }
};

export const getChargesUtility = (doc: CustomerReportDoc): ReportData => {
  // Actual doc
  if (doc?.totalPayable) {
    // For delivery no extra calculations are needed
    if (doc?.documentAadeCode === DOCUMENT_TYPE_AADE_CODE.DELIVERY_NOTE) {
      return {
        rawValue: doc?.totalPayable,
        currentPosSubtotal: '',
        currentNegSubtotal: '',
        isPostedDoc: true,
        isDeliveryNote: true,
      };
    }

    // @ts-expect-error no issue
    if (AADE_CODES_OF_RETURN_MONEY_DOCS.includes(doc?.documentAadeCode)) {
      return {
        rawValue: doc?.totalPayable,
        currentPosSubtotal: '',
        currentNegSubtotal: doc?.totalPayable || '0.00',
        isPostedDoc: true,
        isDeliveryNote: false,
      };
    }

    return {
      rawValue: doc?.totalPayable,
      currentPosSubtotal: doc?.totalPayable || '0.00',
      currentNegSubtotal: '',
      isPostedDoc: true,
      isDeliveryNote: false,
    };
  } else {
    // Money collection
    // @ts-expect-error no issue
    if (NEGATIVE_OPERATION_PAYMENT_TYPES.includes(doc?.paymentType)) {
      return {
        rawValue: doc?.totalValue || '',
        currentPosSubtotal: doc?.totalValue || '0.00',
        currentNegSubtotal: '',
        isPostedDoc: false,
        isDeliveryNote: false,
      };
    }
    return {
      rawValue: doc?.totalValue || '',
      currentPosSubtotal: '',
      currentNegSubtotal: doc?.totalValue || '0.00',
      isPostedDoc: false,
      isDeliveryNote: false,
    };
  }
};

export const getTotalChargesReturns = (docs: CustomerReportDoc[]) => {
  let charges = '0.00';
  let returns = '0.00';

  for (let i = 0; i < docs?.length; i++) {
    const res = getChargesUtility(docs[i]);
    if (res?.currentPosSubtotal) {
      charges = addWithDecimals(charges, res.currentPosSubtotal);
    }

    if (res?.currentNegSubtotal) {
      returns = minusWithDecimals(returns, res.currentNegSubtotal);
    }
  }

  return { charges, returns: Math.abs(Number(returns)).toFixed(2) };
};
