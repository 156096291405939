import { Customer } from '../../../../../interfaces/customer.interface';
import { CustomersReportTableHeaderView } from './CustomersReportTableHeaderView';
import { CustomersReportTableListView } from './CustomersReportTableListView';
import { CustomerTableTotalsView } from './CustomerTableTotalsView';

interface Props {
  customers: Customer[];
}

export const CustomersReportTableSectionView = ({ customers }: Props) => {
  return (
    <div className="flex-center-center-column-nowrap padding-t-10 width-100">
      <div className="width-100">
        <CustomersReportTableHeaderView />
      </div>
      <div className="width-100">
        <CustomersReportTableListView customers={customers} />
        <CustomerTableTotalsView customers={customers} />
      </div>
    </div>
  );
};
