import {
  basketOutline,
  basketSharp,
  cashOutline,
  cashSharp,
  homeOutline,
  homeSharp,
  peopleOutline,
  peopleSharp,
  receiptOutline,
  receiptSharp,
  settingsOutline,
  settingsSharp,
} from 'ionicons/icons';
import { SUBSCRIPTION } from '../interfaces/enumerables';

export interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  planToCheck?: string;
}

export enum PATHS {
  HOME = '/',
  SALES = '/sales',
  SALES_VIEW = '/sales/view',
  CUSTOMERS = '/customers',
  COMPANIES = '/companies',
  PRODUCTS = '/products',
  CLONE_PRODUCT = '/products/clone',
  MONEY_COLLECTION = '/money-collection',
  CREATE_SUB_PATH = 'create',
  SHARED_DOC = '/share/doc',
  SHARED_MONEY_COLLECTION = '/share/money-collection',
  CLONE_SALE = '/sales/clone',
  CLONE_MONEY_COLLECTION = '/money-collection/clone',
  REPORTS = '/reports',
  SETTINGS = '/settings',
}

export const navigationData = [
  {
    title: 'TABS.SALES',
    url: PATHS.SALES,
    iosIcon: receiptOutline,
    mdIcon: receiptSharp,
  },
  {
    title: 'TABS.CUSTOMERS',
    url: PATHS.CUSTOMERS,
    iosIcon: peopleOutline,
    mdIcon: peopleSharp,
  },
  {
    title: 'TABS.PRODUCTS',
    url: PATHS.PRODUCTS,
    iosIcon: basketOutline,
    mdIcon: basketSharp,
  },
  {
    title: 'TABS.MONEY_COLLECTION',
    url: PATHS.MONEY_COLLECTION,
    iosIcon: cashOutline,
    mdIcon: cashSharp,
    planToCheck: SUBSCRIPTION.SUBTOTALS,
  },
  {
    title: 'TABS.REPORTS',
    url: PATHS.REPORTS,
    iosIcon: cashOutline,
    mdIcon: cashSharp,
  },
  {
    title: 'TABS.SETTINGS',
    url: PATHS.SETTINGS,
    iosIcon: settingsOutline,
    mdIcon: settingsSharp,
  },
];

export const appPagesMenu: AppPage[] = [
  {
    title: 'TABS.HOME',
    url: PATHS.HOME,
    iosIcon: homeOutline,
    mdIcon: homeSharp,
  },
  ...navigationData,
];

export const indexOfPlanSensitivePages = [3, 4];
