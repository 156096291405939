import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { SUBSCRIPTION } from '../../interfaces/enumerables';
import { PATHS } from '../../navigation/data';
import { usePlanIsActive } from '../../services/utils/usePlanIsActive';
import { setToast } from '../../store/core/coreSlice';
import { store } from '../../store/store';
import { CustomersSubtotalsReport } from './components/customers/CustomersSubtotalsReport';

export const CustomersSubtotalsReportScreen = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const isAllowed = usePlanIsActive(SUBSCRIPTION.SUBTOTALS);

  const redirectHomeAction = useCallback(() => {
    history.replace(PATHS.HOME);
    store.dispatch(
      setToast({
        value: {
          show: true,
          msg: t('PLANS.NO_ACTIVE_PLAN_FOR_FEATURE'),
          isError: true,
          duration: 30000,
        },
      }),
    );
  }, []);

  useEffect(() => {
    if (!isAllowed) {
      redirectHomeAction();
    }
  }, [isAllowed, redirectHomeAction]);

  return (
    <div className="width-100 padding-t-16 padding-b-16 flex-start-center-column-nowrap padding-r-24 padding-l-24">
      <CustomersSubtotalsReport />
    </div>
  );
};
