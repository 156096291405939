import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { pdfStyles } from '../../../../../components/molecules/pdf/pdfStyles';
import { Customer } from '../../../../../interfaces/customer.interface';

interface Props {
  index: number;
  customer: Customer;
}

export const CustomersTableLinePdf = ({ index, customer }: Props) => {
  return (
    <View style={styles.container}>
      <View style={{ flex: 1, flexDirection: 'column', paddingLeft: 4 }}>
        <View style={styles.indexSection}>
          <Text style={pdfStyles.smallTitleRegular}>{index + 1}.</Text>
        </View>
        <Text style={[pdfStyles.smallTitle, pdfStyles.maxLines2]}>{customer?.companyName}</Text>
      </View>
      <Text style={[pdfStyles.smallTitleRegular, pdfStyles.width80]}>{customer?.customerSubtotal}</Text>
      <Text style={[pdfStyles.smallTitleRegular, pdfStyles.width80]}>{customer?.supplierSubtotal}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  indexSection: {
    position: 'absolute',
    left: -12,
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingTop: 2,
  },
  width60: {
    width: 60,
    textAlign: 'center',
  },
  width40: {
    width: 40,
    textAlign: 'center',
  },
});
