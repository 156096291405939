import { MONEY_COLLECTION_PAYMENT_TYPE, MONEY_COLLECTION_TRADER } from '../api/hooks/useFormMoneyCollection';

export interface CustomerReportResponse {
  data: CustomerReportDoc[];
}

export enum CUSTOMER_REPORT_ENTITY_TYPE {
  INVOICE = 'Invoice',
  MONEY_COLLECTION = 'MoneyCollection',
}

export interface CustomerReportDoc {
  entity_type: CUSTOMER_REPORT_ENTITY_TYPE;
  id: string;
  currentSubtotal: string;
  year: number;
  serialNumber: number;
  updatetAt: string;
  sort_date: string; // 'sentToAadeAt' for Invoice - `createdAt` for MoneyCollection
  totalValue: string; // 'totalValue' for Invoice - `value` for MoneyCollection
  documentDate: string; // 'documentDate' for Invoice - `date` for MoneyCollection
  // Only in Invoice
  documentTypeId: number | null;
  documentTypeName: string | null;
  documentAadeCode: string | null;
  sentToAadeAt: string | null;
  seriesName: string | null;
  taxWithholdPercentage: string | null;
  vatOfProds: string | null;
  taxWithholdAmount: string | null;
  totalPayable: string | null;
  // Only in Money Collection
  paymentType?: MONEY_COLLECTION_PAYMENT_TYPE;
  traderType?: MONEY_COLLECTION_TRADER;
  createdAt?: string;
}
