export const TEST_IDS = {
  Invoice: {
    customerPicker: 'invoiceCustomerPicker',
    invoiceSeriesName: 'invoiceSeriesName',
    productNamePicker: 'invoiceProductNamePicker',
    productQuantityInput: 'invoiceProductQuantityInput',
    productDescriptionInput: 'invoiceProductDescriptionInput',
    productPriceInput: 'invoiceProductPriceInput',
    productDiscountInput: 'invoiceProductDiscountInput',
    taxWithholdSelect: 'invoiceProductTaxWithholdSelect',
    shippingSelect: 'shippingSelect',
    shipFromInput: 'shipFromInput',
    shipToAddressInput: 'shipToAddressInput',
    shipToCitySelect: 'shipToCitySelect',
    shipToPostalCodeSelect: 'shippingSelect',
  },
  Shared: {
    bSelectSearch: 'bSelectSearch',
    deleteIconButton: 'deleteIconButton',
  },
} as const;

export type TEST_IDS_TYPE = {
  [K in keyof typeof TEST_IDS]: (typeof TEST_IDS)[K][keyof (typeof TEST_IDS)[K]];
}[keyof typeof TEST_IDS];

export const DOCUMENT_TYPE_AADE_CODE = {
  RECEIPT: '1',
  RECEIPT_SERVICES: '11.2',
  INVOICE: '1.1',
  INVOICE_INTRA_COMMUNITY_DELIVERIES: '1.2',
  INVOICE_THIRD_COUNTRIES_DELIVERIES: '1.3',
  INVOICE_SERVICES: '2.1',
  INVOICE_SERVICES_INTRA_COMMUNITY: '2.2',
  INVOICE_SERVICES_THIRD_COUNTRIES: '2.3',
  DELIVERY_NOTE: '9.3',
  SELF_DELIVERY_ITEM: '6.1',
  SELF_USE_ITEM: '6.2',
  INVOICE_RETURN_RELATED: '5.1',
  INVOICE_RETURN_UNRELATED: '5.2',
  INVOICE_RETURN_CREDIT_UNRELATED: '5.3',
  INVOICE_FIXED: '1.12',
  TITLE_DEED_NON_OBLIGATORY_ISSUER: '3.1',
} as const;

export type DOCUMENT_TYPE_AADE_CODE_TYPE = (typeof DOCUMENT_TYPE_AADE_CODE)[keyof typeof DOCUMENT_TYPE_AADE_CODE];

export const CUSTOMER_TYPE_AADE_CODE = {
  SAME_COUNTRY_CLIENT: '1455',
  EU_CLIENT: '1456',
  REST_OF_THE_WORLD_CLIENT: '1457',
  PERSON: '1458',
} as const;

export const CUSTOMER_VAT_REGIME_AADE_CODE = {
  NORMAL_VAT: '1',
  REDUCED_VAT: '2',
  ZERO_VAT: '3',
} as const;

export const VAT_PERCENTAGE_AADE_CODE = {
  NO_VAT_PERC: '8',
  PERC_0: '7',
  PERC_4: '6',
  PERC_6: '3',
  PERC_9: '5',
  PERC_13: '2',
  PERC_17: '4',
  PERC_24: '1',
} as const;

export const INVOICE_AADE_CODES = [
  DOCUMENT_TYPE_AADE_CODE.INVOICE,
  DOCUMENT_TYPE_AADE_CODE.INVOICE_INTRA_COMMUNITY_DELIVERIES,
  DOCUMENT_TYPE_AADE_CODE.INVOICE_THIRD_COUNTRIES_DELIVERIES,
  DOCUMENT_TYPE_AADE_CODE.INVOICE_FIXED,
  DOCUMENT_TYPE_AADE_CODE.INVOICE_SERVICES,
  DOCUMENT_TYPE_AADE_CODE.INVOICE_SERVICES_INTRA_COMMUNITY,
  DOCUMENT_TYPE_AADE_CODE.INVOICE_SERVICES_THIRD_COUNTRIES,
  DOCUMENT_TYPE_AADE_CODE.INVOICE_RETURN_RELATED,
  DOCUMENT_TYPE_AADE_CODE.INVOICE_RETURN_UNRELATED,
  DOCUMENT_TYPE_AADE_CODE.INVOICE_RETURN_CREDIT_UNRELATED,
];

// TODO: check if `TITLE_DEED_NON_OBLIGATORY_ISSUER` is needed
export const AADE_CODES_OF_RETURN_MONEY_DOCS = [
  DOCUMENT_TYPE_AADE_CODE.INVOICE_RETURN_RELATED,
  DOCUMENT_TYPE_AADE_CODE.INVOICE_RETURN_UNRELATED,
  DOCUMENT_TYPE_AADE_CODE.INVOICE_RETURN_CREDIT_UNRELATED,
  DOCUMENT_TYPE_AADE_CODE.TITLE_DEED_NON_OBLIGATORY_ISSUER,
];
