import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { pdfStyles } from '../../../../../components/molecules/pdf/pdfStyles';
import { getNewSubtotalUtility, getTotalChargesReturns } from '../../../../../config/utils/reportUtils';
import { CURRENCY } from '../../../../../interfaces/enumerables';
import { CustomerReportResponse } from '../../../../../interfaces/reports.interface';

interface Props {
  data: CustomerReportResponse;
}

export const CustomerTableTotalsPdf = ({ data }: Props) => {
  const { t } = useTranslation();

  const finalSubtotals = useMemo(() => {
    const len = data?.data?.length;
    if (len > -1) {
      return getNewSubtotalUtility(data?.data?.[len - 1]);
    }

    return { rawValue: '0.00', currentPosSubtotal: '', currentNegSubtotal: '' };
  }, [data?.data]);

  const getPosSubtotal = useCallback(() => {
    if (finalSubtotals?.currentPosSubtotal) {
      return finalSubtotals?.currentPosSubtotal;
    }
    return '0.00';
  }, [finalSubtotals?.currentPosSubtotal]);

  const getNegSubtotal = useCallback(() => {
    if (finalSubtotals?.currentNegSubtotal) {
      return finalSubtotals?.currentNegSubtotal;
    }
    return '0.00';
  }, [finalSubtotals?.currentNegSubtotal]);

  const getTotals = useCallback(() => {
    return getTotalChargesReturns(data?.data || []);
  }, [data?.data]);

  return (
    <View style={styles.container}>
      <View style={{ flex: 1, flexDirection: 'column', paddingLeft: 17 }}>
        <Text style={[pdfStyles.smallTitle]}>{t('CUSTOMERS_REPORT.TOTAL')}</Text>
      </View>
      <Text style={[pdfStyles.smallTitle, pdfStyles.width60]}>
        {getTotals()?.charges} {CURRENCY.EURO}
      </Text>
      <Text style={[pdfStyles.smallTitle, pdfStyles.width60]}>
        {getTotals()?.returns} {CURRENCY.EURO}
      </Text>
      <Text style={[pdfStyles.smallTitle, pdfStyles.width60]}>
        {getPosSubtotal()} {CURRENCY.EURO}
      </Text>
      <Text style={[pdfStyles.smallTitle, pdfStyles.width60, { marginRight: 8 }]}>
        {getNegSubtotal()} {CURRENCY.EURO}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingTop: 10,
  },
});
