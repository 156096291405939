import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { PATHS } from '../../../navigation/data';

export const CustomerReportCard = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const goToReport = useCallback(() => {
    history.push(`${PATHS.REPORTS}/customer`);
  }, [history]);

  return (
    <div className="info-card flex-center-center-column-nowrap hoverable settings-card-dimensions" onClick={goToReport}>
      <div className="boldText7 text-center">{t('CUSTOMERS_REPORT.CUSTOMER_REPORT')}</div>
    </div>
  );
};
