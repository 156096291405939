import { useCallback } from 'react';
import { CustomerReportDoc } from '../../../../../interfaces/reports.interface';
import { DocTableLineView } from './DocTableLineView';

interface Props {
  docs: CustomerReportDoc[];
}

export const DocsTableListView = ({ docs }: Props) => {
  const getItems = useCallback(() => {
    return docs?.map((item, index) => {
      return <DocTableLineView key={index} index={index} doc={item} />;
    });
  }, [docs]);

  return <div className="flex-start-start-column-nowrap padding-2 width-100">{getItems()}</div>;
};
