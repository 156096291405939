import { StyleSheet, View } from '@react-pdf/renderer';
import { useCallback } from 'react';
import { CustomerReportResponse } from '../../../../../interfaces/reports.interface';
import { CustomerTableLinePdf } from './CustomerTableLinePdf';

interface Props {
  data: CustomerReportResponse;
}

export const CustomerReportTableListPdf = ({ data }: Props) => {
  const getItems = useCallback(() => {
    return data?.data?.map((item, index) => {
      return <CustomerTableLinePdf key={index} index={index} item={item} />;
    });
  }, [data]);

  return <View style={styles.container}>{getItems()}</View>;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 2,
  },
});
