import { useTranslation } from 'react-i18next';

export const DocsReportTableHeaderView = () => {
  const { t } = useTranslation();

  return (
    <div className="width-100 tableContainer">
      <div className="normalText1 tableTitle center width-80px padding-l-4 height-22px">
        {t('CUSTOMERS_REPORT.DATE')}
      </div>
      <div className="normalText1 tableTitle center width-120px-simple text-center height-22px">
        {t('CUSTOMERS_REPORT.SERIES_NAME_NUMBER_HEADER')}
      </div>
      <div className="normalText1 tableTitle center width-240px text-center height-22px">
        {t('CUSTOMERS_REPORT.DOCUMENT_TYPE')}
      </div>
      <div className="normalText1 tableTitle center width-80px text-center height-22px">
        {t('CUSTOMERS_REPORT.CUSTOMER_SUBTOTAL')}
      </div>
      <div className="normalText1 tableTitle center width-80px text-center height-22px">
        {t('CUSTOMERS_REPORT.TRADER_SUBTOTAL')}
      </div>
      <div className="normalText1 tableTitle center width-80px text-center height-22px">
        {t('CUSTOMERS_REPORT.CUSTOMER_SUBTOTAL')}
      </div>
      <div className="normalText1 center width-80px text-center">{t('CUSTOMERS_REPORT.TRADER_SUBTOTAL')}</div>
    </div>
  );
};
