import { useMutation } from 'react-query';
import { CustomerReportResponse } from '../../../interfaces/reports.interface';
import { apiRQ } from '../../api';

interface Props {
  customerId: string;
  start: string;
  end: string;
  includeMoneyCollection: boolean;
  includeDeliveryNotes: boolean;
  orderBy?: string;
  order?: string;
}

const getCustomerReport = async (params: Props): Promise<CustomerReportResponse> => {
  // Keep only date from start/end dates
  const data = {
    ...params,
    start: params?.start?.split?.('T')?.[0] || undefined,
    end: params?.end?.split?.('T')?.[0] || undefined,
  };
  // @ts-expect-error remove `customerId` from query params since we have it in path params
  delete data?.customerId;

  const resp = await apiRQ.get<CustomerReportResponse>(`/reports/customers/${params.customerId}`, { params: data });

  return resp.data;
};

export const useCustomerMutateReport = () => {
  // @ts-expect-error tsa
  return useMutation<CustomerReportResponse, Error>(getCustomerReport, {
    mutationKey: [useCustomerMutateReport],
  });
};
