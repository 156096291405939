import { PDFViewer } from '@react-pdf/renderer';
import { useCallback, useMemo, useState } from 'react';
import {
  selectCustomersReportData,
  useCustomersSubtotalsReport,
} from '../../../../api/hooks/reports/useCustomersSubtotalsReport';
import { BToggleNotInForm } from '../../../../components/molecules/BToggleNotInForm';
import { SORT_BY_CUSTOMERS } from '../../../../interfaces/enumerables';
import { selectSelectedCompany } from '../../../../store/companies/companiesSlice';
import { useAppSelector } from '../../../../store/hooks';
import { CustomersReportDownloadButton } from './CustomersReportDownloadButton';
import { CustomersReportPdf } from './pdf/CustomersReportPdf';
import { CustomersReportView } from './view/CustomersReportView';

export const CustomersSubtotalsReport = () => {
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const { data, isLoading } = useCustomersSubtotalsReport(SORT_BY_CUSTOMERS.COMPANY_NAME, 'ASC');
  const [includeZeroSubtotals, setIncludeZeroSubtotals] = useState(true);
  const filteredData = useMemo(() => {
    if (!isLoading && data) {
      return selectCustomersReportData(data, includeZeroSubtotals);
    }

    return [];
  }, [data, includeZeroSubtotals, isLoading]);

  const toggleCallback = useCallback(() => {
    setIncludeZeroSubtotals((res) => !res);
  }, []);

  // TODO: remove when testing is done
  const getPdf = useCallback(() => {
    if (filteredData && selectedCompany) {
      return (
        <PDFViewer style={{ width: '100%', height: '1200px' }} showToolbar={true}>
          <CustomersReportPdf company={selectedCompany} customers={filteredData} />
        </PDFViewer>
      );
    }
  }, [filteredData, selectedCompany]);

  const getReport = useCallback(() => {
    if (selectedCompany && filteredData) {
      return <CustomersReportView company={selectedCompany} customers={filteredData} />;
    }
  }, [selectedCompany, filteredData]);

  return (
    <div className="width-100 flex-center-start-column-nowrap">
      <div className="padding-b-10">
        <CustomersReportDownloadButton data={filteredData} />
      </div>
      <div className="padding-t-10 padding-b-10">
        <BToggleNotInForm
          tooltip="ZERO_SUBTOTALS_TOOLTIP"
          label="CUSTOMERS_REPORT.ZERO_SUBTOTALS"
          checked={includeZeroSubtotals}
          clickCallback={toggleCallback}
        />
      </div>
      <div className="scrollable-doc">{getReport()}</div>
      {/*{getPdf()}*/}
    </div>
  );
};
