import { IonItem } from '@ionic/react';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo } from 'react';
import { showError } from '../../../config/utils/formDiffUtils';
import { disableSubmitOnEnter } from '../../../config/utils/formUtils';
import { TooltipInInput } from '../TooltipInInput';

dayjs.locale('el');

interface Props {
  field: any;
  formik: any;
  title: string;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

const dateFormat = 'DD/MM/YYYY';

export const DocDate = ({ field, formik, title, minDate, maxDate }: Props) => {
  const finalDate = dayjs(new Date(formik.values?.[field.name] || ''));
  const hasError = useMemo(() => showError(formik, formik?.errors?.[field?.name]), [formik?.errors]);

  const isDateDisabled = useCallback(
    (day: Dayjs) => {
      if (minDate && day.isBefore(minDate, 'day')) return true;
      if (maxDate && day.isAfter(maxDate, 'day')) return true;

      return false;
    },
    [minDate, maxDate],
  );

  const onChange = (date: dayjs.Dayjs) => {
    const newDate = date.hour(10);
    formik.setFieldValue(field.name, newDate.toISOString());
  };

  const isDisabled = useCallback(() => {
    if (field?.disabled) return true;
  }, [field?.disabled]);

  const getTooltip = useCallback(() => {
    if (field.tooltip) return <TooltipInInput value={field.tooltip} />;
  }, [field.tooltip]);

  return (
    <div>
      <div className="padding-b-16 padding-l-12">{title}</div>
      <div className="positionRelative">
        {getTooltip()}
        <IonItem
          id={`open-popover-${field.name}`}
          onKeyDown={disableSubmitOnEnter}
          className={`width-300px border-radius-input g-select-border cursorPointer ${
            isDisabled() && 'b-disabled-border'
          } ${hasError && 'b-field-border-error'}`}
          mode="md">
          <DatePicker
            id={`datetime-${field.name}`}
            className="normalText4 antd-datetime-container"
            defaultValue={finalDate}
            value={finalDate}
            onChange={onChange}
            format={dateFormat}
            variant="borderless"
            allowClear={false}
            disabledDate={isDateDisabled}
            disabled={isDisabled()}
          />
        </IonItem>
      </div>
    </div>
  );
};
