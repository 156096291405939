import { Document, Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Company } from '../../../../../interfaces/company.interface';
// @ts-expect-error Import
import ManropeRegular from '../../../../../theme/assets/fonts/Manrope-Regular.ttf';
// @ts-expect-error Import
import ManropeBold from '../../../../../theme/assets/fonts/Manrope-Bold.ttf';
// @ts-expect-error Import
import ManropeMedium from '../../../../../theme/assets/fonts/Manrope-Medium.ttf';

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PdfCompanyLogo } from '../../../../../components/molecules/pdf/PdfCompanyLogo';
import { PdfCompanyTitle } from '../../../../../components/molecules/pdf/PdfCompanyTitle';
import { pdfStyles } from '../../../../../components/molecules/pdf/pdfStyles';
import { Customer } from '../../../../../interfaces/customer.interface';
import { CustomerReportResponse } from '../../../../../interfaces/reports.interface';
import { CustomerReportPdfTrader } from './CustomerReportPdfTrader';
import { CustomerReportTableSectionPdf } from './CustomerReportTableSectionPdf';

interface Props {
  company: Company;
  logo: string | undefined | null;
  trader: Customer;
  data: CustomerReportResponse;
}

// Register Font
Font.register({
  family: 'Manrope',
  fonts: [
    {
      src: ManropeRegular,
    },
    {
      src: ManropeMedium,
    },
    {
      src: ManropeBold,
    },
  ],
});

export const CustomerReportPdf = ({ company, logo, trader, data }: Props) => {
  const { t } = useTranslation();

  const getTitle = useCallback(() => {
    return (
      <View style={styles.title}>
        <Text style={pdfStyles.headerTitle}>{t('CUSTOMERS_REPORT.CUSTOMER_REPORT')}</Text>
      </View>
    );
  }, [t]);

  const getSection = useCallback(() => {
    return (
      <View style={styles.section}>
        <View>
          <View style={pdfStyles.flexStartSpaceRow}>
            <PdfCompanyTitle company={company} />
            <PdfCompanyLogo company={company} logo={logo} />
          </View>
          <View style={{ paddingTop: 4 }} />
          {getTitle()}
          <CustomerReportPdfTrader trader={trader} />
          <View style={{ paddingTop: 4 }} />
          <CustomerReportTableSectionPdf data={data} />
        </View>
      </View>
    );
  }, [company, data, getTitle, logo, trader]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {getSection()}
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
    marginTop: 0,
    paddingTop: 0,
    marginBottom: 20,
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  title: {
    textDecoration: 'underline',
    justifyContent: 'center',
    alignItems: 'center',
  },
  page: {
    fontFamily: 'Manrope',
    fontSize: 12,
    flexDirection: 'column',
    backgroundColor: 'white',
    paddingTop: 30,
    paddingBottom: 30,
  },
});
