import { useCallback } from 'react';
import { getNewSubtotalUtility } from '../../../../../config/utils/reportUtils';
import { CustomerReportDoc } from '../../../../../interfaces/reports.interface';

interface Props {
  doc: CustomerReportDoc;
}

export const LineSubtotalsSection = ({ doc }: Props) => {
  const getNewSubtotal = useCallback(() => {
    return getNewSubtotalUtility(doc);
  }, [doc]);

  const getSubtotalsSection = useCallback(() => {
    const results = getNewSubtotal();

    return (
      <>
        <div className="normalText1 text-center width-80px">{results?.currentPosSubtotal || '0.00'}</div>
        <div className="normalText1 text-center width-80px">{results?.currentNegSubtotal || '0.00'}</div>
      </>
    );
  }, [getNewSubtotal]);

  return getSubtotalsSection();
};
