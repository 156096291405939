import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { pdfStyles } from '../../../../../components/molecules/pdf/pdfStyles';
import { getFullDateTime } from '../../../../../config/utils/dateHelpers';
import { Customer } from '../../../../../interfaces/customer.interface';

interface Props {
  trader: Customer;
}

export const CustomerReportPdfTrader = ({ trader }: Props) => {
  const { t } = useTranslation();

  return (
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={pdfStyles.smallTitle}>{t('PDF.TRADER')}</Text>
        <View style={{ flexDirection: 'row' }}>
          <Text style={pdfStyles.body}>{trader?.companyName}</Text>
        </View>
      </View>
      <View style={styles.columnCenter}>
        <Text style={pdfStyles.smallTitle}>{t('PDF.DATE_LOWER')}</Text>
        <View style={{ flexDirection: 'row' }}>
          <Text style={pdfStyles.body}>{getFullDateTime(new Date().toISOString())}</Text>
        </View>
      </View>
      <View style={styles.columnEnd}>
        <Text style={pdfStyles.smallTitle}>{t('PDF.VAT_NUMBER')}</Text>
        <View style={{ flexDirection: 'row' }}>
          <Text style={pdfStyles.body}>{trader?.vatNumber}</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
  },
  column: {
    flexDirection: 'column',
    width: '50%',
    alignItems: 'flex-start',
  },
  columnCenter: {
    flexDirection: 'column',
    width: '33%',
    alignItems: 'center',
  },
  columnEnd: {
    flexDirection: 'column',
    width: '50%',
    alignItems: 'flex-end',
  },
});
