import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { SUBSCRIPTION } from '../../../interfaces/enumerables';
import { PATHS } from '../../../navigation/data';
import { usePlanIsActive } from '../../../services/utils/usePlanIsActive';

export const CustomersSubtotalsReportCard = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const isAllowed = usePlanIsActive(SUBSCRIPTION.SUBTOTALS);

  const goToReport = useCallback(() => {
    history.push(`${PATHS.REPORTS}/customers`);
  }, [history]);

  const getOption = useCallback(() => {
    if (isAllowed) {
      return (
        <div
          className="info-card flex-center-center-column-nowrap hoverable settings-card-dimensions"
          onClick={goToReport}>
          <div className="boldText7 text-center">{t('CUSTOMERS_REPORT.CURRENT_CUSTOMERS_SUBTOTALS_CARD')}</div>
        </div>
      );
    }

    return null;
  }, [goToReport, isAllowed, t]);

  return getOption();
};
