import { PDFViewer } from '@react-pdf/renderer';
import { useFormik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AnyObject } from 'yup';
import { useCustomerMutateReport } from '../../../../api/hooks/reports/useCustomerMutateReport';
import { useCompany } from '../../../../api/hooks/useCompanies';
import { useCompanyLogo } from '../../../../api/hooks/useCompanyLogo';
import { Loading } from '../../../../components/atoms/Loading';
import { getInitialFormData } from '../../../../config/utils/formUtils';
import { SORT_DIRECTION } from '../../../../interfaces/enumerables';
import { selectSelectedCompany } from '../../../../store/companies/companiesSlice';
import { useAppSelector } from '../../../../store/hooks';
import { CustomerReportDownloadButton } from './CustomerReportDownloadButton';
import { CustomerReportFilters } from './CustomerReportFilters';
import { CustomerReportPdf } from './pdf/CustomerReportPdf';
import { CustomerReportTable } from './view/CustomerReportTable';

interface Props {
  data: any;
  validationSchema: AnyObject;
}

export const CustomerReport = ({ data, validationSchema }: Props) => {
  const { t } = useTranslation();
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const { data: companyData } = useCompany(selectedCompany?.id || '');
  const { data: logoData } = useCompanyLogo(companyData?.logoUrl || '');
  const initialFormData = useMemo(() => getInitialFormData(data), [data]);
  const { mutate, data: reportData, isLoading } = useCustomerMutateReport();

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema,
    onSubmit: (values) => {
      const params = {
        ...values,
        order: SORT_DIRECTION.ASC,
        customerId: values.customerId.id || values.customerId,
      };

      mutate(params);
    },
  });

  const customer = useMemo(() => formik?.values[data?.fields?.[0]?.name], [data?.fields, formik?.values]);

  // TODO: remove when testing is done
  const getPdf = useCallback(() => {
    if (reportData && companyData) {
      return (
        <PDFViewer style={{ width: '100%', height: '1200px' }} showToolbar={true}>
          <CustomerReportPdf company={companyData} logo={logoData} trader={customer} data={reportData} />
        </PDFViewer>
      );
    }
  }, [companyData, customer, logoData, reportData]);

  const getTable = useCallback(() => {
    if (selectedCompany && reportData?.data?.length) {
      return <CustomerReportTable customer={customer} company={selectedCompany} data={reportData} />;
    }

    // Show no data message only when at least one requests has been done
    if (reportData) {
      return <div className="mediumText5">{t('CUSTOMERS_REPORT.NO_DATA_FOR_THIS_SEARCH')}</div>;
    }
  }, [customer, reportData, selectedCompany, t]);

  const getDownloadButton = useCallback(() => {
    if (companyData && customer && reportData?.data?.length) {
      return (
        <div>
          <CustomerReportDownloadButton company={companyData} logo={logoData} trader={customer} data={reportData} />
        </div>
      );
    }
  }, [companyData, customer, logoData, reportData]);

  const getLoading = useCallback(() => {
    if (isLoading) {
      return <Loading />;
    }

    return null;
  }, [isLoading]);

  return (
    <div className="width-100 flex-center-start-column-nowrap">
      {getLoading()}
      <div className="mediumText6 margin-b-10" style={{ textDecoration: 'underline' }}>
        {t('CUSTOMERS_REPORT.CUSTOMER_REPORT')}
      </div>
      <div className="padding-b-20">
        <CustomerReportFilters
          formik={formik}
          customerField={data.fields[0]}
          startDateField={data.fields[1]}
          endDateField={data.fields[2]}
          includeMoneyCollectionField={data.fields[3]}
          includeDeliveryNotesField={data.fields[4]}
        />
      </div>
      {/* {getPdf()} */}
      {getDownloadButton()}
      <div className="scrollable-doc">{getTable()}</div>
    </div>
  );
};
