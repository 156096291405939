import { useCallback } from 'react';
import { getChargesUtility } from '../../../../../config/utils/reportUtils';
import { CustomerReportDoc } from '../../../../../interfaces/reports.interface';

interface Props {
  doc: CustomerReportDoc;
}

export const LineChargesSection = ({ doc }: Props) => {
  const getChargesSection = useCallback(() => {
    const results = getChargesUtility(doc);

    return (
      <>
        <div className="normalText1 text-center width-80px padding-l-6">{results?.currentPosSubtotal || '0.00'}</div>
        <div className="normalText1 text-center width-80px">{results?.currentNegSubtotal || '0.00'}</div>
      </>
    );
  }, [doc]);

  return getChargesSection();
};
