import 'moment/locale/el';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CustomerScreen } from '../screens/Customers/CustomerScreen';
import { CustomersScreen } from '../screens/Customers/CustomersScreen';
import { NewCustomerScreen } from '../screens/Customers/NewCustomerScreen';
import { HomeScreen } from '../screens/Home/HomeScreen';
import { MoneyCollectionListScreen } from '../screens/MoneyCollection/MoneyCollectionListScreen';
import { MoneyCollectionScreen } from '../screens/MoneyCollection/MoneyCollectionScreen';
import { NewMoneyCollectionScreen } from '../screens/MoneyCollection/NewMoneyCollectionScreen';
import { ProductScreen } from '../screens/Products/ProductScreen';
import { ProductsScreen } from '../screens/Products/ProductsScreen';
import { CustomerReportScreen } from '../screens/Reports/CustomerReportScreen';
import { CustomersSubtotalsReportScreen } from '../screens/Reports/CustomersSubtotalsReportScreen';
import { ReportsScreen } from '../screens/Reports/ReportsScreen';
import { NewSaleScreen } from '../screens/Sales/NewSaleScreen';
import { SaleScreen } from '../screens/Sales/SaleScreen';
import { SalesScreen } from '../screens/Sales/SalesScreen';
import { CompaniesScreen } from '../screens/Settings/Companies/CompaniesScreen';
import { CompanyScreen } from '../screens/Settings/Companies/CompanyScreen';
import { SettingsScreen } from '../screens/Settings/SettingsScreen';
import { PATHS } from './data';
import Navbar from './Navbar';

export const NavigationContainer = () => {
  return (
    <Navbar>
      <Switch>
        <Route path={PATHS.HOME} exact={true} component={HomeScreen} />
        <Route path={PATHS.SALES} exact={true} component={SalesScreen} />
        <Route path={`${PATHS.SALES}/view/:id`} exact={true} component={SaleScreen} />
        <Route path={`${PATHS.SALES}/create`} exact={true} component={NewSaleScreen} />
        <Route path={`${PATHS.SALES}/edit/:id`} exact={true} component={NewSaleScreen} />
        <Route path={`${PATHS.SALES}/clone/:id`} exact={true} component={NewSaleScreen} />
        <Route path={PATHS.CUSTOMERS} exact={true} component={CustomersScreen} />
        <Route path={`${PATHS.CUSTOMERS}/view/:id`} exact={true} component={CustomerScreen} />
        <Route path={`${PATHS.CUSTOMERS}/create`} exact={true} component={NewCustomerScreen} />
        <Route path={`${PATHS.CUSTOMERS}/:id`} exact={true} component={NewCustomerScreen} />
        <Route path={PATHS.COMPANIES} exact={true} component={CompaniesScreen} />
        <Route path={`${PATHS.COMPANIES}/:id`} exact={true} component={CompanyScreen} />
        <Route path={PATHS.PRODUCTS} exact={true} component={ProductsScreen} />
        <Route path={`${PATHS.PRODUCTS}/clone/:id`} exact={true} component={ProductScreen} />
        <Route path={`${PATHS.PRODUCTS}/:id`} exact={true} component={ProductScreen} />
        <Route path={PATHS.MONEY_COLLECTION} exact={true} component={MoneyCollectionListScreen} />
        <Route path={`${PATHS.MONEY_COLLECTION}/view/:id`} exact={true} component={MoneyCollectionScreen} />
        <Route path={`${PATHS.MONEY_COLLECTION}/create`} exact={true} component={NewMoneyCollectionScreen} />
        <Route path={`${PATHS.MONEY_COLLECTION}/:id`} exact={true} component={NewMoneyCollectionScreen} />
        <Route path={`${PATHS.MONEY_COLLECTION}/clone/:id`} exact={true} component={NewMoneyCollectionScreen} />
        <Route path={PATHS.REPORTS} exact={true} component={ReportsScreen} />
        <Route path={`${PATHS.REPORTS}/customer`} exact={true} component={CustomerReportScreen} />
        <Route path={`${PATHS.REPORTS}/customers`} exact={true} component={CustomersSubtotalsReportScreen} />
        <Route path={PATHS.SETTINGS} component={SettingsScreen} />
        <Route path="*">
          <Redirect to={PATHS.HOME} />
        </Route>
      </Switch>
    </Navbar>
  );
};
