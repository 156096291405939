import { useQuery } from 'react-query';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { api } from '../../api';

interface PublicDocResponse {
  success: boolean;
  error?: string;
  errorCode?: string;
  data?: InvoiceInterface;
}

const getDoc = async (id: string): Promise<PublicDocResponse> => {
  const resp = await api.get<PublicDocResponse>(`/share/doc/${id}`);
  return resp.data;
};

export const usePublicDoc = (id: string) => {
  return useQuery<PublicDocResponse, Error>(['usePublicDoc', id], () => getDoc(id), {
    enabled: !!id,
    cacheTime: 10000,
    staleTime: 10000,
    refetchOnWindowFocus: false,
    select: (data) => {
      if (data?.data?.staticCompany) data.data.company = data?.data?.staticCompany;
      if (data?.data?.staticCustomer) data.data.customer = data?.data?.staticCustomer;

      return data;
    },
  });
};
