import { Pagination } from 'antd';
import { ComponentType, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { SORT_DIRECTION } from '../../interfaces/enumerables';
import { GetPaginationData, SortBy } from '../../interfaces/forms.inteface';
import { getPaginationLayoutStyle } from '../../services/utils/helpers';
import { useHasActivePlan } from '../../services/utils/useHasActivePlan';
import { images } from '../../theme/assets/img';
import { DataPlaceholder } from '../atoms/DataPlaceholder';
import { Loading } from '../atoms/Loading';
import { Filters } from './Filters';

interface Props<T, Tf extends { [key: string]: SortBy }> {
  useDataHook: (
    page: number,
    sortByValue: any,
    sortDirection: SORT_DIRECTION,
    search: string,
    staticParam?: { [key: string]: never },
  ) => UseQueryResult<GetPaginationData<T>, Error>;
  ItemCardData: ComponentType<{ index: number; key: number; data: T }>;
  sortByFilter: Tf;
  sortByDefaultKey: keyof Tf;
  sortDirectionValue?: SORT_DIRECTION;
  hideCreateButton?: boolean;
  hideSearchButton?: boolean;
  staticParam?: { [key: string]: never };
  specificLayoutClass?: string;
  initSearchValue?: string;
  cssPostfix?: string;
}

export const EntityPaginationData = <T extends { id: number | string }, Tf extends { [key: string]: SortBy }>({
  useDataHook,
  ItemCardData,
  sortByFilter,
  sortByDefaultKey,
  sortDirectionValue = SORT_DIRECTION.ASC,
  hideCreateButton,
  hideSearchButton,
  staticParam,
  specificLayoutClass,
  initSearchValue,
  cssPostfix,
}: Props<T, Tf>) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  // Parse query parameters
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const initialSortBy = sortByFilter[searchParams?.get('sortBy') || ''] || sortByFilter[sortByDefaultKey];
  const initialSortDirection = (searchParams.get('sortDirection') as SORT_DIRECTION) || sortDirectionValue;
  const initialSearch = searchParams.get('search') || initSearchValue || '';
  const initialPage = Number(searchParams.get('page') || 1);

  const [page, setPage] = useState<number>(initialPage);
  const [sortBy, setSortBy] = useState<SortBy>(initialSortBy);
  const [sortDirection, setSortDirection] = useState<SORT_DIRECTION>(initialSortDirection);
  const [search, setSearch] = useState<string>(initialSearch);
  const { isLoading, data, isError, error } = useDataHook(page, sortBy.value, sortDirection, search, staticParam);
  const layoutClass = getPaginationLayoutStyle(specificLayoutClass || '', data?.total || 0, cssPostfix);

  const hasActivePlans = useHasActivePlan();

  // Sync page state with URL params
  useEffect(() => {
    const currentPage = Number(searchParams.get('page') || '1');
    setPage(currentPage);
  }, [searchParams]);

  const updateURL = useCallback(
    (newPage: number) => {
      searchParams.set('page', newPage.toString());
      history.push({ search: searchParams.toString() });
    },
    [history, searchParams],
  );

  const showTotal = (total: number) => {
    return t('SHARED.TOTAL_PAGINATION', { total });
  };

  const changePage = useCallback(
    (newPage: number) => {
      setPage(newPage);
      updateURL(newPage);
    },
    [updateURL],
  );

  const renderList = () => {
    if (data && data?.data?.length > 0) {
      return data.data.map((item, index) => {
        return <ItemCardData index={index} key={index} data={item} />;
      });
    }
  };

  return (
    <div className="width-100 flex-center-center-column-nowrap">
      <div className="filter-page">
        <div className="width-100 list-padding-horizontal">
          <Filters
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            search={search}
            setSearch={setSearch}
            sortByFilterData={sortByFilter}
            hideCreateButton={hideCreateButton || !hasActivePlans}
            hideSearchButton={hideSearchButton}
          />
        </div>
        {data && data?.data?.length > 0 && (
          <div>
            <div className={`list-items-itemOfList padding-t-10 padding-b-10 list-padding-horizontal ${layoutClass}`}>
              {renderList()}
            </div>
            <div className="width-100 margin-t-24 flex-end-center-row-nowrap list-padding-horizontal">
              <Pagination
                size="small"
                current={page}
                total={data?.total || 0}
                defaultPageSize={20}
                showSizeChanger={false}
                showTotal={showTotal}
                onChange={changePage}
                hideOnSinglePage={true}
              />
            </div>
          </div>
        )}
        {isError && <DataPlaceholder imgSource={images.somethingWentWrong} msg={'SHARED.SOMETHING_WENT_WRONG'} />}
        {data?.data?.length === 0 && <DataPlaceholder imgSource={images.noData} msg={'SHARED.NO_DATA'} />}
      </div>
      {isLoading && <Loading />}
    </div>
  );
};
